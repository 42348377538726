/** @jsxImportSource @emotion/react */
import { RiBarcodeLine, RiAlertLine } from 'react-icons/ri';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { theme } from '@omnigenbiodata/ui';
import { useStyles } from './component.styles';
import Box from '@mui/material/Box';
import { MESSAGES } from '../../../../../../../../core/constants/forms.constants';
import { SamplePosition } from '../../../../../../../../core/types/common.types';
import { FormikErrors } from 'formik';

interface ScannedCellProps {
  hasError?: string | FormikErrors<SamplePosition>;
  onSelect: (index: number) => void;
  onRemove: (index: number) => void;
  position: number;
  label: string;
  value?: SamplePosition;
  editMode: boolean;
}

function ScannedCell({ hasError, onSelect, position, label, value, editMode }: ScannedCellProps) {
  const classes = useStyles();
  return (
    <div
      key={`scanned-cell-${position}`}
      data-testid={`scanned-cell-${position}`}
      css={[
        classes.sampleColumn,
        !value && !hasError && classes.sampleColumnEmpty,
        value && !hasError && classes.sampleColumnFull,
        (hasError || value?.participantWithdrawn) && classes.sampleColumnError,
        (editMode || value) && classes.isSelectable,
      ]}
      onClick={() => {
        if (editMode || value) {
          onSelect(position);
        }
      }}
    >
      <div css={classes.sampleIndex}>{label}</div>
      <div css={classes.sampleContent}>
        {value && (
          <Tooltip
            arrow
            title={
              <Box p={2} alignItems="middle">
                <Typography variant="body1" component="span" css={classes.tooltipTypog}>
                  {!hasError && <RiBarcodeLine size={25} css={classes.tooltipIcon} />}
                  {hasError && <RiAlertLine size={25} css={classes.tooltipIcon} />}{' '}
                  {hasError ? MESSAGES.barcodeInvalid : value.sampleBarcode}
                </Typography>
                {value.participantWithdrawn && (
                  <Typography variant="body1" component="span" justifyContent="center" css={classes.tooltipTypog}>
                    WITHDRAWN
                  </Typography>
                )}
              </Box>
            }
            aria-label={value.sampleBarcode}
            data-testid={`sample-${value.sampleBarcode}`}
            placement="top"
          >
            <div>
              <IconButton aria-label="Valid Sample" size="small">
                <RiBarcodeLine
                  color={theme.palette.common.white}
                  title={value.sampleBarcode}
                  fontSize={30}
                  alignmentBaseline="middle"
                />
              </IconButton>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
}

export default ScannedCell;
