import i18next from 'i18next';
import portal_en from '../../../translations/en/portal.json';

export const setupI18next = (defaultLanguage = 'en') => {
  i18next.init({
    interpolation: { escapeValue: false },
    lng: defaultLanguage,
    fallbackLng: 'en',
    resources: {
      en: {
        portal: portal_en,
      },
    },
  });
  return i18next;
};
