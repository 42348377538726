export const createStorageBoxMutation = `
mutation createSampleBox($input: CreateSampleBoxInput!) {
    createSampleBox(input: $input) {
        boxBarcode
        shelfPosition
        samples {
            sampleBarcode
        }
    },
}
`;

export const editStorageBoxMutation = `
mutation editSampleBox($input: EditSampleBoxInput!) {
    editSampleBox(input: $input) {
        boxBarcode
        shelfPosition
        samples {
            sampleBarcode
        }
    },
}
`;

export const dispatchSampleMutation = `
mutation dispatchSample($input: DispatchSampleInput!) {
    dispatchSample(input: $input) {
        participantId
    },
}
`;

export const receiveSampleMutation = `
mutation receiveSample($input: ReceiveSampleInput!) {
    receiveSample(input: $input) {
        orderRef
        orderNum
    },
}
`;

export const printLabelsMutation = `
mutation printLabels($input: PrintLabelsInput!) {
    printLabels(input: $input) {
        pdf
    }
}
`;

export const requestSampleExport = `
mutation requestSampleExport {
    requestSampleExport {
        runId
    }
}`;

export const updateShippingMutation = `
mutation updateShipment($input: UpdateShipmentInput!) {
  updateShipment(input: $input) {
    sampleBoxes {
        barcode
        count
        shelfPosition
      }
    totalSampleCount
    shipmentId
  }
}
`;

export const dispatchShipmentMutation = `
  mutation completeStagedShipment {
    completeStagedShipment {
        sampleBoxes {
            barcode
            count
            shelfPosition
          }
        totalSampleCount
        shipmentId
    }
  }
`;
