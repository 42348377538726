import { createSlice } from '@reduxjs/toolkit';
import { StorageState } from './types';
import { createStorageThunk, getSampleExportThunk, getStorageBoxesThunk, getStorageBoxThunk } from './thunks';
import { StoreStatus, SamplePosition } from '../../core/types/common.types';

// Initial State
const initialState: StorageState = {
  errors: null,
  status: StoreStatus.IDLE,
  response: null,
  boxesErrors: null,
  boxesStatus: StoreStatus.IDLE,
  boxesResponse: null,
  boxErrors: null,
  boxStatus: StoreStatus.IDLE,
  boxResponse: null,
  exportErrors: null,
  exportStatus: StoreStatus.IDLE,
  exportResponse: null,
};

const addEmptySamples = (samples: any) => {
  const array = Array(81) as SamplePosition[];
  for (const sample of samples) {
    array[sample.samplePosition] = sample;
  }
  return array;
};

// Slice
export const storageSlice = createSlice({
  name: 'storage',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
    }),
    clearExport: (state) => ({
      ...state,
      exportErrors: null,
      exportStatus: StoreStatus.IDLE,
      exportResponse: null,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(createStorageThunk.pending.type, (state: StorageState) => {
      state.status = StoreStatus.BUSY;
      state.errors = null;
      state.response = null;
    });

    builder.addCase(createStorageThunk.fulfilled.type, (state: StorageState, action: any) => {
      state.status = StoreStatus.IDLE;
      state.response = action.payload;
      state.errors = null;
    });

    builder.addCase(createStorageThunk.rejected.type, (state: StorageState, { payload }: any) => {
      state.status = StoreStatus.ERROR;
      state.errors = payload;
      state.response = null;
    });

    builder.addCase(getStorageBoxesThunk.pending.type, (state: StorageState) => {
      state.boxesStatus = StoreStatus.BUSY;
      state.boxesErrors = null;
      state.boxesResponse = null;
    });

    builder.addCase(getStorageBoxesThunk.fulfilled.type, (state: StorageState, action: any) => {
      state.boxesStatus = StoreStatus.IDLE;
      state.boxesResponse = action.payload;
      state.boxesErrors = null;
    });

    builder.addCase(getStorageBoxesThunk.rejected.type, (state: StorageState, { payload }: any) => {
      state.boxesStatus = StoreStatus.ERROR;
      state.boxesErrors = payload;
      state.boxesResponse = null;
    });

    builder.addCase(getStorageBoxThunk.pending.type, (state: StorageState) => {
      state.boxStatus = StoreStatus.BUSY;
      state.boxErrors = null;
      state.boxResponse = null;
    });

    builder.addCase(getStorageBoxThunk.fulfilled.type, (state: StorageState, action: any) => {
      state.boxStatus = StoreStatus.IDLE;
      state.boxResponse = {
        ...action.payload,
        samples: addEmptySamples(action.payload.samples),
      };
      state.boxErrors = null;
    });

    builder.addCase(getStorageBoxThunk.rejected.type, (state: StorageState, { payload }: any) => {
      state.boxStatus = StoreStatus.ERROR;
      state.boxErrors = payload;
      state.boxResponse = null;
    });
    builder.addCase(getSampleExportThunk.pending.type, (state: StorageState) => {
      state.exportStatus = StoreStatus.BUSY;
      state.exportErrors = null;
      state.exportResponse = null;
    });

    builder.addCase(getSampleExportThunk.fulfilled.type, (state: StorageState, action: any) => {
      state.exportStatus = StoreStatus.IDLE;
      state.exportResponse = action.payload;
      state.exportErrors = null;
    });

    builder.addCase(getSampleExportThunk.rejected.type, (state: StorageState, { payload }: any) => {
      state.exportStatus = StoreStatus.ERROR;
      state.exportErrors = payload;
      state.exportResponse = null;
    });
  },
});

// Action creators
export const { reset, clearExport } = storageSlice.actions;
export * from './thunks';
export * from './selectors';
export * from './types';
export default storageSlice.reducer;
