import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../core/constants/routes.constants';
import { ButtonRow } from '@omnigenbiodata/ui';

interface ButtonsProps {
  backButton?: boolean;
  backLabel?: string;
  forwardLabel: string;
  isDisabled?: boolean;
  onForward: () => void;
  onBack?: () => void;
}

function Buttons({
  forwardLabel,
  onForward,
  onBack,
  backButton,
  backLabel = 'Home',
  isDisabled = false,
}: ButtonsProps) {
  const navigate = useNavigate();
  const goHome = () => navigate(ROUTES.root);
  const goBack = () => navigate(-1);
  return (
    <ButtonRow
      backIcon={null}
      forwardIcon={null}
      buttonSize="large"
      backLabel={backButton ? 'Back' : backLabel}
      forwardLabel={forwardLabel}
      onForward={onForward}
      onBack={backButton ? goBack : onBack || goHome}
      forwardColor="primary"
      isDisabled={isDisabled}
    />
  );
}

export default Buttons;
