/** @jsxImportSource @emotion/react */
import { RiArrowRightLine, RiSearch2Line } from 'react-icons/ri';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { FormInput, FormSelect, theme } from '@omnigenbiodata/ui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { MESSAGES, STATUS_OPTIONS } from '../../../../../../core/constants/forms.constants';

interface SampleSearchProps {
  onSearch: (values: { orderNo: string; status: string }) => void;
  showTitle?: boolean;
  orderNo?: string;
}

function SampleSearch({ onSearch, showTitle, orderNo }: SampleSearchProps) {
  const validationSchema = yup.object({
    orderNo: yup.string().required(MESSAGES.orderRequired).min(4),
    status: yup.string(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      orderNo: orderNo || '',
      status: '',
    },
    validateOnMount: true,
    validationSchema,
    onSubmit: (values) => {
      onSearch(values);
    },
  });

  return (
    <Paper variant="outlined" sx={{ background: 'none' }}>
      <Box p={{ xs: 1, sm: 1, md: 4 }}>
        {showTitle && (
          <Typography component="h2" paragraph variant="h6">
            Sample Search
          </Typography>
        )}
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={7}>
              <FormInput
                label="Order Number"
                type="text"
                name={`orderNo`}
                error={formik.errors.orderNo}
                touched={formik.touched.orderNo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.orderNo}
                margin="none"
                startAdornment={<RiSearch2Line />}
              />
            </Grid>
            <Grid item xs={12} sm>
              <FormSelect
                label="Status"
                name={`status`}
                data-testid="status"
                error={formik.errors.status}
                touched={formik.touched.status}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Status"
                margin="none"
                options={STATUS_OPTIONS}
                value={formik.values.status}
              />
            </Grid>
            <Grid item xs={12} sm="auto">
              <Button
                type="submit"
                variant="outlined"
                size="large"
                fullWidth
                sx={{
                  height: '56px',
                  fontSize: '20px',
                  borderColor: theme.palette.primary.light,
                }}
              >
                Search <RiArrowRightLine />
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Paper>
  );
}

export default SampleSearch;
