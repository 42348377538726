import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import printReducer from './print';
import receiveReducer from './receive';
import dispatchReducer from './dispatch';
import storageReducer from './storage';
import sampleSearchReducer from './search';
import shippingReducer from './shipping';
import shippingHistoryReducer from './shippingHistory';

const store = configureStore({
  reducer: {
    print: printReducer,
    receive: receiveReducer,
    dispatch: dispatchReducer,
    storage: storageReducer,
    sampleSearch: sampleSearchReducer,
    shipping: shippingReducer,
    shippingHistory: shippingHistoryReducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
  ],
});
export type RootState = ReturnType<typeof store.getState>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => any = useDispatch; // Export a hook that can be reused

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
