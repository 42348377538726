export const getParticipantsByStatusQuery = `
query getParticipantsByStatusQuery($status: OrderStatus!) {
  getParticipantsByStatus(status: $status) {
    participantId
  }
}`;

export const getStorageBoxesQuery = `
query searchSampleBoxes($searchTerm: String!) {
  searchSampleBoxes(searchTerm: $searchTerm) {
    boxBarcode
    shelfPosition
    samples {
      sampleBarcode
    }
    locked
    createdTs
  },
}
`;

export const getOrderByOrderNumberQuery = `
query getOrderByOrderNumberQuery($status: OrderStatus!, $orderNo: Int!) {
  getOrderByOrderNumber(status: $status, orderNo: $orderNo) {
    postcode
    sampleBarcode
    sampleLinkTs
  }
}`;

export const getSampleExportStatusQuery = `
query getSampleExportStatusQuery {
  getSampleExportStatus {
    createdTs
    exportLink
    jobError
    jobStatus
    runId
    updatedTs
  }
}`;

export const getDailyDispatchCountQuery = `
query getDailyDispatchCountQuery {
  getDailyDispatchCount {
    count
  }
}
`;

export const getShippingQuery = `
  query getStagedShipmentQuery {
    getStagedShipment {
      sampleBoxes {
        barcode
        count
        shelfPosition
      }
      totalSampleCount
      shipmentId
      createdTs
    }
  }
`;

export const getStorageBoxQuery = `
  query getStorageBoxQuery($boxBarcode: String!) {
    getStorageBox(boxBarcode: $boxBarcode) {
      boxBarcode
      shelfPosition
      samples {
        sampleBarcode
        participantWithdrawn
        samplePosition
      }
      locked
    }
  }
  `;

export const getShipmentManifestQuery = `
  query getShipmentManifestQuery($shipmentId: String!) {
    getShipmentManifest(shipmentId: $shipmentId) {
      link
    }
  }
  `;

export const getStagedManifestQuery = `
  query getStagedManifestQuery {
    getStagedManifest {
      Sample_ID
      Sex
      Sample_Position
      Consent_version
      Matrix_Rack
    }
  }
  `;

export const getShippingHistoryQuery = `
  query listShipmentsQuery {
    listShipments {
      sampleBoxes {
        barcode
        count
        shelfPosition
      }
      totalSampleCount
      shipmentId
      createdTs
    }
  }
  `;
