export enum StoreStatus {
  BUSY = 'busy',
  IDLE = 'idle',
  ERROR = 'error',
}

export enum OrderStatus {
  CREATED = 'CREATED',
  DISPATCHED = 'DISPATCHED',
}

export enum OrderCondition {
  Viable = 'Viable',
  'Non-viable' = 'Non-viable',
}

export interface GraphQlErrorLocation {
  line: number;
  column: number;
  sourceName: string | null;
}

export interface GraphQlError {
  path: string[];
  data: string | null;
  errorType: string;
  errorInfo: string | null;
  locations: GraphQlErrorLocation[];
  message: string;
}

export interface Sample {
  qrCode: string;
  sampleBarcode?: string;
  status?: string;
  participantID: string;
}

export interface Order {
  orderRef: string;
  orderNum: string;
}

export interface SampleSearch {
  status: string;
  orderNo: string;
  sampleBarcode: string;
  sampleLinkTs: string;
  postcode: string;
}

export interface SamplePosition {
  sampleBarcode: string;
  participantWithdrawn?: boolean;
  samplePosition?: string;
}

export interface ExportStatus {
  runId: string;
  jobStatus: string;
  jobError?: string;
  createdTs: string;
  updatedTs?: string;
  exportLink?: string;
}

export interface StorageBox {
  boxBarcode: string;
  shelfPosition: string;
  samples: SamplePosition[];
  createdTs?: string;
  isEdit?: boolean;
  inStaging?: boolean;
}

export interface StagedBox {
  barcode: string;
  count: number;
  shelfPosition: string;
}

export interface Shipment {
  sampleBoxes: StagedBox[];
  totalSampleCount: number;
  shipmentId: string;
  createdTs: string;
}

export interface SampleSearch {
  status: string;
  orderNo: string;
  sampleBarcode: string;
  sampleLinkTs: string;
  postcode: string;
}

export interface Participant {
  status: string;
  participantID: string;
}

export interface ManifestRow {
  Sample_ID: string;
  Sex: string;
  Sample_Position: number;
  Consent_version: string;
  Matrix_Rack?: string;
}

export type GetParticipantsByStatusResponse = {
  data?: { getParticipantsByStatus: string[] };
  errors?: GraphQlError[];
};

export type GetOrderByOrderNumberResponse = {
  data?: {
    getOrderByOrderNumber: {
      sampleBarcode: string;
      sampleLinkTs: string;
      postcode: string;
    };
  };
  errors?: GraphQlError[];
};

export type PrintLabelsResponse = {
  data?: { printLabels: { pdf: string } };
  errors?: GraphQlError[];
};

export type ReceiveSampleResponse = {
  data?: { receiveSample: Order };
  errors?: GraphQlError[];
};

export type DispatchSampleResponse = {
  data?: { dispatchSample: { participantId: string } };
  errors?: GraphQlError[];
};

export type DispatchCountResponse = {
  data?: { getDailyDispatchCount: { count: string } };
  errors?: GraphQlError[];
};

export type CreateStorageResponse = {
  data?: { createSampleBox?: StorageBox; editSampleBox?: StorageBox };
  errors?: GraphQlError[];
};

export type GetStorageBoxesResponse = {
  data?: { searchSampleBoxes: StorageBox[] };
  errors?: GraphQlError[];
};

export type GetStorageBoxResponse = {
  data?: { getStorageBox: StorageBox };
  errors?: GraphQlError[];
};

export type GetShippingResponse = {
  data?: { getStagedShipment: Shipment };
  errors?: GraphQlError[];
};

export type UpdateShippingResponse = {
  data?: { updateShipment: Shipment };
  errors?: GraphQlError[];
};

export type DispatchShipmentResponse = {
  data?: { completeStagedShipment: Shipment };
  errors?: GraphQlError[];
};

export type RequestSampleResponse = {
  data?: { sampleExportRequest: { runId: string } };
  errors?: GraphQlError[];
};

export type SampleExportStatusResponse = {
  data?: { getSampleExportStatus: ExportStatus };
  errors?: GraphQlError[];
};

export type GetShipmentManifestResponse = {
  data?: { getShipmentManifest: { link: string } };
  errors?: GraphQlError[];
};

export type GetStagedManifestResponse = {
  data?: { getStagedManifest: ManifestRow[] };
  errors?: GraphQlError[];
};

export type GetShippingHistoryResponse = {
  data?: { listShipments: Shipment[] };
  errors?: GraphQlError[];
};
