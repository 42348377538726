import { RootState } from '..';
import { Shipment, StoreStatus, ManifestRow } from '../../core/types/common.types';

export const errorSelector = (state: RootState): any | null => state.shipping.errors || null;

export const isBusySelector = (state: RootState): boolean => state.shipping.status === StoreStatus.BUSY;

export const responseSelector = (state: RootState): Shipment | null => state.shipping.response || null;

export const manifestErrorSelector = (state: RootState): any | null => state.shipping.manifestErrors || null;

export const manifestIsBusySelector = (state: RootState): boolean => state.shipping.manifestStatus === StoreStatus.BUSY;

export const manifestResponseSelector = (state: RootState): { link: string } | null =>
  state.shipping.manifestResponse || null;

export const stagedManifestErrorSelector = (state: RootState): any | null =>
  state.shipping.stagedManifestErrors || null;

export const stagedManifestIsBusySelector = (state: RootState): boolean =>
  state.shipping.stagedManifestStatus === StoreStatus.BUSY;

export const stagedManifestResponseSelector = (state: RootState): ManifestRow[] | null =>
  state.shipping.stagedManifestResponse || null;

export const dispatchErrorSelector = (state: RootState): any | null => state.shipping.dispatchErrors || null;

export const dispatchIsBusySelector = (state: RootState): boolean => state.shipping.dispatchStatus === StoreStatus.BUSY;

export const dispatchResponseSelector = (state: RootState): Shipment | null => state.shipping.dispatchResponse || null;
