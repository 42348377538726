import { css } from '@emotion/react';
import { theme } from '@omnigenbiodata/ui';

export const useStyles = () => {
  return {
    sampleRow: css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    `,
    sampleColumn: css`
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
      font-size: 11px;
      text-align: center;
      height: 55px;
      margin: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      position: relative;
      transition: all 0.3s linear;
      box-shadow: none;
    `,
    isSelectable: css`
      cursor: pointer;
    `,
    sampleIndex: css`
      padding-top: 1px;
      padding-bottom: 1px;
      padding-left: 5px;
      padding-right: 5px;
      position: absolute;
      top: 3px;
      left: 3px;
      border-radius: 50%;
    `,
    sampleContent: css`
      flex-grow: 1;
      display: flex;
      align-items: center;
      height: auto;
    `,
    sampleColumnEmpty: css`
      border-width: 1px;
      border-style: solid;
      border-color: ${theme.palette.grey[300]};
      background: ${theme.palette.grey[100]};
      color: ${theme.palette.grey[700]};
    `,
    sampleColumnFull: css`
      color: ${theme.palette.common.white};
      box-shadow: 3px 3px 2px rgba(0;0;0;0.2);
      background: ${theme.palette.success.main};
    `,
    sampleColumnError: css`
      background: ${theme.palette.error.main};
    `,
    tooltipTypog: css`
      display: flex;
      align-items: center;
    `,
    tooltipIcon: css`
      margin-right: 10px;
    `,
  };
};
