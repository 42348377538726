import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '@omnigenbiodata/ui';
import ROUTES from '../../core/constants/routes.constants';

function Auth() {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={ROUTES.root} />;
  }

  return <Outlet />;
}

export default Auth;
