import MainLayout from '../../../../components/layouts/MainLayout';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import SampleSearch from './components/SampleSearch';
import { sampleSearchThunk, reset } from '../../../../store/search';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { useTranslation } from 'react-i18next';
import { Formatter, Loader, Message, theme } from '@omnigenbiodata/ui';
import { isBusySelector, responseSelector, errorSelector } from '../../../../store/search';
import { useEffect } from 'react';

function SampleSearchScene() {
  const { t } = useTranslation('portal');
  const dispatch = useAppDispatch();
  const isBusy = useAppSelector(isBusySelector);
  const errors = useAppSelector(errorSelector);
  const result = useAppSelector(responseSelector);

  const handleSampleSearch = (values: any) => {
    dispatch(sampleSearchThunk(values));
  };

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  return (
    <MainLayout title={t('search.title')}>
      <Box my={2}>
        <SampleSearch onSearch={handleSampleSearch} orderNo={result?.orderNo} />
      </Box>
      {errors && (
        <Message severity="error" title={t('alerts.searchFailed.title') as string}>
          {errors[0].message}
        </Message>
      )}
      {result && !result.postcode && (
        <Message severity="info" title={t('alerts.noSample.title') as string}>
          {t('alerts.noSample.text')}
        </Message>
      )}
      {result?.postcode && (
        <Paper sx={{ background: 'none', mt: 2 }}>
          <Typography
            component="h2"
            variant="h5"
            paragraph
            align="center"
            sx={{
              pb: 1,
              borderBottom: 1,
              borderColor: theme.palette.primary.lighter,
            }}
          >
            <strong>Order Number {result.orderNo}</strong>
          </Typography>
          <Typography variant="body1" paragraph align="center">
            {t('forms.status')}: {result.status}
          </Typography>
          {result.sampleBarcode && (
            <>
              <Typography variant="body1" paragraph align="center">
                {t('forms.barcode')}: {result.sampleBarcode}
              </Typography>
              <Typography variant="body1" paragraph align="center">
                {t('forms.sampleLinkTs')}: <Formatter input={result.sampleLinkTs} format="friendlyDateTime" />
              </Typography>
            </>
          )}
          <Typography variant="body1" paragraph align="center">
            {t('forms.postcode')}: {result.postcode}
          </Typography>
        </Paper>
      )}
      <Loader isVisible={isBusy} label={t('loaders.search') as string} />
    </MainLayout>
  );
}

export default SampleSearchScene;
