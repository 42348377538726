import { RootState } from '../index';
import { Order, StoreStatus } from '../../core/types/common.types';

export const errorSelector = (state: RootState): any | null => state.receive.errors || null;

export const isBusySelector = (state: RootState): boolean => state.receive.status === StoreStatus.BUSY;

export const responseSelector = (state: RootState): string => state.receive.response || null;

export const listSelector = (state: RootState): Order[] => state.receive.sessionList;

export const hasSuccessSelector = (state: RootState): any | null => (state.receive.response ? true : false);
