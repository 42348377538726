import { createAsyncThunk } from '@reduxjs/toolkit';
import portalApi from '../../core/api/portal.api';
import {
  GetShippingResponse,
  UpdateShippingResponse,
  DispatchShipmentResponse,
  GetShipmentManifestResponse,
  GetStagedManifestResponse,
  GraphQlError,
} from '../../core/types/common.types';

export const getShippingThunk = createAsyncThunk<
  // Return type of the payload creator
  GetShippingResponse,
  // First argument to the payload creator
  undefined,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('shipping/get', async (values, thunkApi) => {
  const response: GetShippingResponse = await portalApi.getShipping();

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }
  return response.data?.getStagedShipment as GetShippingResponse;
});

export const updateShippingThunk = createAsyncThunk<
  // Return type of the payload creator
  UpdateShippingResponse,
  // First argument to the payload creator
  { boxBarcode: string; operation: string },
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('shipping/update', async (values, thunkApi) => {
  const response: UpdateShippingResponse = await portalApi.updateShipping(values);

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }
  return response.data?.updateShipment as UpdateShippingResponse;
});

export const dispatchShipmentThunk = createAsyncThunk<
  // Return type of the payload creator
  DispatchShipmentResponse,
  // First argument to the payload creator
  undefined,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('shipping/dispatch', async (values, thunkApi) => {
  const response: DispatchShipmentResponse = await portalApi.dispatchShipment();

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }

  return response.data?.completeStagedShipment as DispatchShipmentResponse;
});

export const getShipmentManifestThunk = createAsyncThunk<
  // Return type of the payload creator
  GetShipmentManifestResponse,
  // First argument to the payload creator
  string,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('shipping/manifest', async (shipmentId, thunkApi) => {
  const response: GetShipmentManifestResponse = await portalApi.getShipmentManifest(shipmentId);

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }

  return response.data?.getShipmentManifest as GetShipmentManifestResponse;
});

export const getStagedManifestThunk = createAsyncThunk<
  // Return type of the payload creator
  GetStagedManifestResponse,
  // First argument to the payload creator
  undefined,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('shipping/stagedManifest', async (values, thunkApi) => {
  const response: GetStagedManifestResponse = await portalApi.getStagedManifest();

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }

  return response.data?.getStagedManifest as GetStagedManifestResponse;
});
