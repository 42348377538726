import { createAsyncThunk } from '@reduxjs/toolkit';
import portalApi from '../../core/api/portal.api';
import { GetOrderByOrderNumberResponse, GraphQlError } from '../../core/types/common.types';

export const sampleSearchThunk = createAsyncThunk<
  // Return type of the payload creator
  GetOrderByOrderNumberResponse,
  // First argument to the payload creator
  { orderNo: string; status: string },
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('sampleSearch', async (values, thunkApi) => {
  let order;
  let errors;
  if (values.status) {
    const response: GetOrderByOrderNumberResponse = await portalApi.getOrderByOrderNumber(values);
    errors = response.errors;
    order = response.data?.getOrderByOrderNumber;
  } else {
    const createdResponse: GetOrderByOrderNumberResponse = await portalApi.getOrderByOrderNumber({
      orderNo: values.orderNo,
      status: 'CREATED',
    });
    const dispatchedResponse: GetOrderByOrderNumberResponse = await portalApi.getOrderByOrderNumber({
      orderNo: values.orderNo,
      status: 'DISPATCHED',
    });
    errors = dispatchedResponse.errors || createdResponse.errors;
    order =
      (dispatchedResponse.data?.getOrderByOrderNumber && {
        status: 'DISPATCHED',
        ...dispatchedResponse.data.getOrderByOrderNumber,
      }) ||
      (createdResponse.data?.getOrderByOrderNumber && {
        status: 'CREATED',
        ...createdResponse.data.getOrderByOrderNumber,
      });
  }

  if (errors) {
    return thunkApi.rejectWithValue(errors);
  }
  return order as GetOrderByOrderNumberResponse;
});
