import { createAsyncThunk } from '@reduxjs/toolkit';
import portalApi from '../../core/api/portal.api';
import {
  CreateStorageResponse,
  GetStorageBoxesResponse,
  GetStorageBoxResponse,
  GraphQlError,
  RequestSampleResponse,
  SampleExportStatusResponse,
  StorageBox,
} from '../../core/types/common.types';
import { GraphQLError } from 'graphql';

export const createStorageThunk = createAsyncThunk<
  // Return type of the payload creator
  CreateStorageResponse,
  // First argument to the payload creator
  StorageBox,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('storage/create', async (values: StorageBox, thunkApi) => {
  const response: CreateStorageResponse = await portalApi.createStorage(values);

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  } else if (!response.data?.createSampleBox && !response.data?.editSampleBox) {
    throw new GraphQLError('Server failed to respond');
  }
  return (
    (response.data?.createSampleBox as CreateStorageResponse) || (response.data?.editSampleBox as CreateStorageResponse)
  );
});

export const getStorageBoxesThunk = createAsyncThunk<
  // Return type of the payload creator
  GetStorageBoxesResponse,
  // First argument to the payload creator
  string,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('storage/getStorageBoxesThunk', async (status, thunkApi) => {
  const response: GetStorageBoxesResponse = await portalApi.getStorageBoxes(status);
  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }
  return response.data?.searchSampleBoxes as GetStorageBoxesResponse;
});

export const getStorageBoxThunk = createAsyncThunk<
  // Return type of the payload creator
  GetStorageBoxResponse,
  // First argument to the payload creator
  string,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('storage/getStorageBoxThunk', async (boxBarcode, thunkApi) => {
  const response: GetStorageBoxResponse = await portalApi.getStorageBox(boxBarcode);

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }
  return response.data?.getStorageBox as GetStorageBoxResponse;
});

export const getSampleExportThunk = createAsyncThunk<
  // Return type of the payload creator
  SampleExportStatusResponse,
  // First argument to the payload creator
  undefined,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('storage/getExport', async (values, thunkApi) => {
  // initiate export creation
  const requestResponse: RequestSampleResponse = await portalApi.requestSampleExport();
  if (requestResponse.errors) {
    return thunkApi.rejectWithValue(requestResponse.errors);
  }
  // poll for created export
  const exportResponse: SampleExportStatusResponse = await pollExportStatus();
  if (exportResponse?.errors) {
    return thunkApi.rejectWithValue(exportResponse.errors);
  }
  return exportResponse.data?.getSampleExportStatus as SampleExportStatusResponse;
});

function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const pollExportStatus = async (): Promise<any> => {
  await wait(1000);
  return portalApi.getSampleExportStatus().then((response) => {
    if (
      response.data?.getSampleExportStatus.exportLink ||
      response.errors ||
      response.data?.getSampleExportStatus.jobError
    ) {
      return response as SampleExportStatusResponse;
    }
    return pollExportStatus();
  });
};
