import { createAsyncThunk } from '@reduxjs/toolkit';
import portalApi from '../../core/api/portal.api';
import { GetParticipantsByStatusResponse, PrintLabelsResponse, GraphQlError } from '../../core/types/common.types';

export const getParticipantsByStatusThunk = createAsyncThunk<
  // Return type of the payload creator
  GetParticipantsByStatusResponse,
  // First argument to the payload creator
  string,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('print/getParticipantsByStatusThunk', async (status, thunkApi) => {
  const response: GetParticipantsByStatusResponse = await portalApi.getParticipantsByStatus(status);

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }
  return response.data?.getParticipantsByStatus as GetParticipantsByStatusResponse;
});

export const printLabelsThunk = createAsyncThunk<
  // Return type of the payload creator
  PrintLabelsResponse,
  // First argument to the payload creator
  string[],
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('print/printLabels', async (values, thunkApi) => {
  const response: PrintLabelsResponse = await portalApi.printLabels(values);

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }
  return response.data?.printLabels as PrintLabelsResponse;
});
