import { useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { useAppSelector } from '../../../../../../store';
import SuccessLayout from '../../../../../../components/layouts/SuccessLayout';
import { printCountSelector, printResponseSelector } from '../../../../../../store/print';
import { Loader } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import printJS from 'print-js-updated';
import ROUTES from '../../../../../../core/constants/routes.constants';

function SuccessScene() {
  const { t } = useTranslation('portal');
  const printCount = useAppSelector(printCountSelector);
  const printResponse = useAppSelector(printResponseSelector);
  const [printError, setPrintError] = useState(false);
  const [printLoading, setPrintLoading] = useState(
    // @ts-expect-error window Cypress does not exist as type
    !!printResponse && !window.Cypress,
  );

  const print = () => {
    setPrintLoading(true);
    printJS({
      printable: printResponse,
      type: 'pdf',
      base64: true,
      onLoadingEnd: () => setPrintLoading(false),
      onLoadingStart: () => setPrintLoading(true),
      onError: () => {
        setPrintError(printError);
        setPrintLoading(false);
      },
    });
  };

  useEffect(() => {
    // @ts-expect-error no window Cypress type
    if (printResponse && !window.Cypress) {
      print();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!printResponse) {
    return <Navigate to={ROUTES.root} />;
  }

  return (
    <>
      <SuccessLayout
        title={t('print.title')}
        subtitle={printError ? t('print.error.title') : t('print.success.title', { count: printCount })}
        message={printError ? t('print.error.text') : null}
        forwardLabel={t('buttons.reprint')}
        onForward={print}
      />
      <Loader isVisible={printLoading} label={t('loaders.print') as string} />
    </>
  );
}

export default SuccessScene;
