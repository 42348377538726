import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import { RiPhoneLockLine } from 'react-icons/ri';
import { useAuth, FormInput, Loader, AuthLayout } from '@omnigenbiodata/ui';
import { MESSAGES } from '../../../../core/constants/forms.constants';
import { AUTH_CHALLENGES } from '../../../../core/constants/aws.constants';
import { useNavigate } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { MFA_ISSUER } from '../../../../core/constants/aws.constants';
import ROUTES from '../../../../core/constants/routes.constants';
import Buttons from '../../../../components/forms/Buttons';
import { useTranslation } from 'react-i18next';

const validationSchema = yup.object().shape({
  otp: yup.string().required(MESSAGES.otpRequired),
});

function MultiFactor() {
  const { hasError, isBusy, challengeName, completeSetupMfa, completeMfa, setupCode, user } = useAuth();
  const { t } = useTranslation('portal');
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: { otp: '' },
    validationSchema,
    onSubmit: (values: any) => {
      const { otp } = values;
      if (challengeName === AUTH_CHALLENGES.MFA_SETUP) {
        completeSetupMfa(otp);
      } else {
        completeMfa(otp);
      }
    },
  });

  const handleCancel = () => {
    navigate(ROUTES.auth);
  };

  return (
    <AuthLayout
      title={t('mfa.title')}
      text={t('mfa.text')}
      hasError={hasError}
      errorTitle={t('alerts.mfaFailed.title')}
      errorText={t('alerts.mfaFailed.text')}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box mb={8}>
          <FormInput
            error={formik.errors.otp}
            name="otp"
            label={t('forms.otp')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.otp}
            value={formik.values.otp}
            startAdornment={<RiPhoneLockLine />}
          />
        </Box>
        {setupCode && user && (
          <>
            <div style={{ textAlign: 'center', marginBottom: 90 }}>
              <QRCode
                value={
                  `otpauth://totp/${MFA_ISSUER}:` + user.username + '?secret=' + setupCode + `&issuer=${MFA_ISSUER}`
                }
              />
            </div>
            <span id="setupCode" style={{ opacity: 0 }}>
              {setupCode}
            </span>
          </>
        )}
        <Buttons
          onBack={handleCancel}
          onForward={formik.submitForm}
          backLabel={t('buttons.back') as string}
          forwardLabel={t('buttons.confirm')}
        />
      </form>
      <Loader isVisible={isBusy} label={t('loaders.auth') as string} />
    </AuthLayout>
  );
}

export default MultiFactor;
