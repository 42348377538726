import { BrowserRouter as Router } from 'react-router-dom';

import Scenes from './scenes';
import { ScrollToTop, EnvFlag, AuthProvider, AuthModal } from '@omnigenbiodata/ui';
import ROUTES from './core/constants/routes.constants';
import ENV from './core/constants/environment.constants';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <AuthProvider authPath={ROUTES.auth} postLoginPath={ROUTES.root} postLogoutPath={ROUTES.authSignOut}>
        <AuthModal message="Your session will expire in 5 minutes. Please ensure you have completed your work, or sign out now." />
        <Scenes />
        <EnvFlag env={ENV.ENVIRONMENT as any} version={ENV.VERSION} />
      </AuthProvider>
    </Router>
  );
}

export default App;
