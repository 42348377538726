import MainLayout from '../../../../../../components/layouts/MainLayout';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Message, Loader, ButtonRow } from '@omnigenbiodata/ui';
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import { DataGrid, GridAlignment } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import {
  isBusySelector,
  errorSelector,
  responseSelector,
  shippingHistoryThunk,
} from '../../../../../../store/shippingHistory';
import {
  getShipmentManifestThunk,
  manifestResponseSelector,
  manifestIsBusySelector,
} from '../../../../../../store/shipping';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../../../core/constants/routes.constants';
import moment from 'moment';

const columns = (downloadManifest: (shipmentId: string) => void) => [
  {
    field: 'shipmentId',
    headerName: 'Shipment ID',
    flex: 1,
  },
  {
    field: 'totalSampleCount',
    headerName: 'Number of samples',
    flex: 0.5,
  },
  {
    field: 'createdTs',
    headerName: 'Created On',
    valueFormatter: (params: any) => moment(params.value).format('DD-MM-YY h:mma'),
    flex: 0.5,
  },
  {
    field: 'sampleBoxes',
    headerName: '',
    align: 'right' as GridAlignment,
    flex: 0.5,
    renderCell: (params: any) => (
      <Button data-testid={`manifest-download-${params.id}`} onClick={() => downloadManifest(params.id)}>
        Download Manifest
      </Button>
    ),
  },
];

function ManageScene() {
  const { t } = useTranslation('portal');
  const dispatch = useAppDispatch();
  const errors = useAppSelector(errorSelector);
  const list = useAppSelector(responseSelector);
  const isBusy = useAppSelector(isBusySelector);
  const manifest = useAppSelector(manifestResponseSelector);
  const manifestLoading = useAppSelector(manifestIsBusySelector);
  const navigate = useNavigate();
  const goHome = () => navigate(ROUTES.root);

  useEffect(() => {
    dispatch(shippingHistoryThunk());
  }, [dispatch]);

  useEffect(() => {
    if (manifest) {
      window.open(manifest.link, '_blank');
    }
  }, [manifest]);

  const downloadManifest = (shipmentId: string) => {
    dispatch(getShipmentManifestThunk(shipmentId));
  };

  return (
    <MainLayout title={t('shippingHistory.title')}>
      <Container maxWidth="sm" sx={{ paddingX: 0, paddingY: 2 }}>
        {errors && (
          <Message severity="error" title={t('alerts.shippingHistoryFailed.title') as string}>
            {errors[0].message}
          </Message>
        )}
      </Container>
      <Box mb={4} sx={{ bgcolor: 'background.paper' }}>
        <DataGrid
          loading={isBusy}
          rows={list || []}
          autoHeight
          columnBuffer={4}
          getRowId={(row) => row.shipmentId}
          columns={columns(downloadManifest)}
          pageSizeOptions={[10, 25, 50]}
          initialState={{ pagination: { paginationModel: { pageSize: 50 } } }}
        />
      </Box>
      <ButtonRow
        buttonSize="large"
        backLabel={t('buttons.home') as string}
        showForward={false}
        onBack={goHome}
        forwardColor="primary"
        backIcon={null}
      />
      <Loader isVisible={manifestLoading} label={t('loaders.manifest') as string} />
    </MainLayout>
  );
}

export default ManageScene;
