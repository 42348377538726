import { RootState } from '../index';
import { SampleSearch, StoreStatus } from '../../core/types/common.types';

export const errorSelector = (state: RootState): any | null => state.sampleSearch.errors || null;

export const isBusySelector = (state: RootState): boolean => state.sampleSearch.status === StoreStatus.BUSY;

export const responseSelector = (state: RootState): SampleSearch | null => state.sampleSearch.response || null;

export const hasSuccessSelector = (state: RootState): boolean => (state.sampleSearch.response ? true : false);
