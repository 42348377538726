import { useEffect } from 'react';
import SuccessLayout from '../../../../../../components/layouts/SuccessLayout';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router';
import { Loader } from '@omnigenbiodata/ui';
import { useAppSelector, useAppDispatch } from '../../../../../../store';
import {
  getShipmentManifestThunk,
  manifestResponseSelector,
  manifestIsBusySelector,
  dispatchResponseSelector,
} from '../../../../../../store/shipping';
import ROUTES from '../../../../../../core/constants/routes.constants';

function SuccessScene() {
  const { t } = useTranslation('portal');
  const shipment = useAppSelector(dispatchResponseSelector);
  const manifest = useAppSelector(manifestResponseSelector);
  const manifestLoading = useAppSelector(manifestIsBusySelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (manifest) {
      window.open(manifest.link, '_blank');
    }
  }, [manifest]);

  if (!shipment) {
    return <Navigate to={ROUTES.root} />;
  }

  const downloadManifest = () => {
    dispatch(getShipmentManifestThunk(shipment.shipmentId));
  };

  return (
    <>
      <SuccessLayout
        title={t('createShipment.success.title')}
        subtitle={t('createShipment.success.text', {
          sampleTotal: shipment.totalSampleCount,
          boxTotal: shipment.sampleBoxes.length,
        })}
        forwardLabel={t('buttons.downloadManifest')}
        onForward={downloadManifest}
      />
      <Loader isVisible={manifestLoading} label={t('loaders.manifest') as string} />
    </>
  );
}

export default SuccessScene;
