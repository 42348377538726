import { createAsyncThunk } from '@reduxjs/toolkit';
import portalApi from '../../core/api/portal.api';
import { DispatchCountResponse, DispatchSampleResponse, GraphQlError } from '../../core/types/common.types';
import { GraphQLError } from 'graphql';

export const dispatchSampleThunk = createAsyncThunk<
  // Return type of the payload creator
  DispatchSampleResponse,
  // First argument to the payload creator
  { qrCode: string; sampleBarcode: string },
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('dispatch', async (values, thunkApi) => {
  const response: DispatchSampleResponse = await portalApi.dispatchSample(values);

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  } else if (!response.data?.dispatchSample) {
    throw new GraphQLError('Server failed to respond');
  }
  return response.data.dispatchSample as DispatchSampleResponse;
});

export const getCountThunk = createAsyncThunk<
  // Return type of the payload creator
  DispatchCountResponse,
  // First argument to the payload creator
  undefined,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('dispatch/count', async (values, thunkApi) => {
  const response: DispatchCountResponse = await portalApi.getDailyDispatchCount();

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  } else if (!response.data?.getDailyDispatchCount) {
    throw new GraphQLError('Server failed to respond');
  }
  return response.data.getDailyDispatchCount as DispatchCountResponse;
});
