import { createSlice } from '@reduxjs/toolkit';
import { ReceiveState } from './types';
import { StoreStatus } from '../../core/types/common.types';
import { receiveSampleThunk } from './thunks';

// Initial State
const initialState: ReceiveState = {
  errors: null,
  status: StoreStatus.IDLE,
  response: null,
  sessionList: [],
};

// Slice
export const receiveSlice = createSlice({
  name: 'receive',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
    }),
    clear: (state) => ({
      ...initialState,
      sessionList: state.sessionList,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(receiveSampleThunk.pending.type, (state) => {
      state.status = StoreStatus.BUSY;
    });

    builder.addCase(receiveSampleThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatus.IDLE;
      state.response = action.payload;
      state.errors = null;
      state.sessionList.unshift({
        sampleBarcode: action.meta.arg.sampleBarcode,
        ...action.payload,
      });
    });

    builder.addCase(receiveSampleThunk.rejected.type, (state, { payload }: any) => {
      state.status = StoreStatus.ERROR;
      state.errors = payload;
      state.response = null;
    });
  },
});

// Action creators
export const { reset, clear } = receiveSlice.actions;
export * from './thunks';
export * from './selectors';
export * from './types';
export default receiveSlice.reducer;
