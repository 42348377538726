import { RootState } from '../../store';
import { StoreStatus } from '../types/common.types';

export const MOCK_PENDING = [
  { participantId: '1' },
  { participantId: '1' },
  { participantId: '2' },
  { participantId: '3' },
  { participantId: '4' },
  { participantId: '5' },
  { participantId: '6' },
  { participantId: '7' },
  { participantId: '8' },
  { participantId: '9' },
  { participantId: '10' },
  { participantId: '11' },
  { participantId: '12' },
  { participantId: '13' },
  { participantId: '14' },
  { participantId: '15' },
  { participantId: '16' },
  { participantId: '17' },
  { participantId: '18' },
  { participantId: '19' },
  { participantId: '20' },
];

export const MOCK_ORDER = {
  sampleCode: '2108027023919',
  outgoingCode:
    'JGB62022232067824000048EFF020E11170523170523STL01FLAT5ODEONPARADEWD246PY9ZGBRCB13NACB13NAIDHJIHIGIJHBaGJb1013',
  returnCode:
    'JGB6209H232067824000048F8899400180523TSS01ZF829515225GB11HOPESTREETYARDCB13NA9ZGBRN154QLbEIDeJcHAHBeFbcD1023',
};

export const MOCK_PARTICIPANT = {
  participantId: '1',
  status: 'pending',
};

export const MOCK_STORAGE_BOX = {
  boxBarcode: '01OMNI0001',
  shelfPosition: '11',
  createdTs: '2023-07-28T14:56:58Z',
  samples: [
    { sampleBarcode: '0000123456701', samplePosition: 0 },
    { sampleBarcode: '0000123456702', samplePosition: 1 },
    { sampleBarcode: '0000123456703', samplePosition: 2 },
    { sampleBarcode: '0000123456704', samplePosition: 3 },
    ...Array(77),
  ],
};

export const MOCK_STORAGE_BOX_WITHDRAWALS = {
  boxBarcode: '01OMNI0002',
  shelfPosition: '12',
  createdTs: '2023-07-28T14:56:58Z',
  samples: [
    { sampleBarcode: '0000123456701', withdrawn: true, samplePosition: 0 },
    { sampleBarcode: '0000123456702', withdrawn: false, samplePosition: 1 },
    { sampleBarcode: '0000123456703', withdrawn: true, samplePosition: 2 },
    { sampleBarcode: '0000123456704', withdrawn: false, samplePosition: 3 },
    ...Array(77),
  ],
};

export const MOCK_STORAGE_BOX_SPACES = {
  boxBarcode: '01OMNI0002',
  shelfPosition: '10',
  createdTs: '2023-07-28T14:56:58Z',
  samples: [
    { sampleBarcode: '0000123456781' },
    null,
    null,
    { sampleBarcode: '0000123456782' },
    { sampleBarcode: '0000123456783' },
    null,
    { sampleBarcode: '0000123456784' },
    ...Array(74),
  ],
};

export const MOCK_STORAGE_BOXES = [
  MOCK_STORAGE_BOX,
  MOCK_STORAGE_BOX_SPACES,
  { ...MOCK_STORAGE_BOX, boxBarcode: '01OMNI0003' },
  { ...MOCK_STORAGE_BOX, boxBarcode: '01OMNI0004' },
  { ...MOCK_STORAGE_BOX, boxBarcode: '01OMNI0005' },
];

export const MOCK_STAGED_BOXES = {
  createdTs: '2023-07-28T14:56:58Z',
  sampleBoxes: [
    {
      barcode: '01OMNI0001',
      count: 4,
      shelfPosition: '1',
    },
    {
      barcode: '01OMNI0002',
      count: 4,
      shelfPosition: '2',
    },
    {
      barcode: '01OMNI0003',
      count: 4,
      shelfPosition: '3',
    },
  ],
  totalSampleCount: 12,
  shipmentId: 'c64cd8a3-c21b-4506-90c7-0f743a1f0bd0',
};

export const MOCK_SAMPLE_SEARCH = {
  sampleBarcode: '2108027023919',
  sampleLinkTs: '',
  postcode: 'SE1 3AE',
};

export const MOCK_EXPORT = {
  runId: '123',
  createdTs: '01-01-01',
  jobStatus: 'SUCCEEDED',
  exportLink: 'download.csv',
};

export const MOCK_STAGED_MANIFEST = [
  {
    Sample_ID: '1',
    Sex: 'Female',
    Sample_Position: '1',
    Consent_version: '5.2',
  },
  {
    Sample_ID: '2',
    Sex: 'Female',
    Sample_Position: '2',
    Consent_version: '5.2',
  },
];

export const MOCK_ROOT_STATE: RootState = {
  print: {
    errors: null,
    status: StoreStatus.IDLE,
    response: { pending: [], printed: [] },
    printErrors: null,
    printStatus: StoreStatus.IDLE,
    printResponse: null,
    printCount: 0,
  },
  receive: {
    errors: null,
    status: StoreStatus.IDLE,
    response: null,
    sessionList: [],
  },
  dispatch: {
    errors: null,
    status: StoreStatus.IDLE,
    response: null,
    countErrors: null,
    countStatus: StoreStatus.IDLE,
    countResponse: null,
    sessionList: [],
  },
  storage: {
    errors: null,
    status: StoreStatus.IDLE,
    response: null,
    boxErrors: null,
    boxStatus: StoreStatus.IDLE,
    boxResponse: null,
    boxesErrors: null,
    boxesStatus: StoreStatus.IDLE,
    boxesResponse: null,
    exportErrors: null,
    exportStatus: StoreStatus.IDLE,
    exportResponse: null,
  },
  sampleSearch: {
    errors: null,
    status: StoreStatus.IDLE,
    response: null,
  },
  shippingHistory: {
    errors: null,
    status: StoreStatus.IDLE,
    response: null,
  },
  shipping: {
    errors: null,
    status: StoreStatus.IDLE,
    response: null,
    dispatchErrors: null,
    dispatchStatus: StoreStatus.IDLE,
    dispatchResponse: null,
    manifestErrors: null,
    manifestStatus: StoreStatus.IDLE,
    manifestResponse: null,
    stagedManifestErrors: null,
    stagedManifestStatus: StoreStatus.IDLE,
    stagedManifestResponse: null,
  },
};
