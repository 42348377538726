import { createSlice } from '@reduxjs/toolkit';
import { ShippingState } from './types';
import {
  updateShippingThunk,
  getShippingThunk,
  dispatchShipmentThunk,
  getShipmentManifestThunk,
  getStagedManifestThunk,
} from './thunks';
import { StoreStatus } from '../../core/types/common.types';

// Initial State
const initialState: ShippingState = {
  errors: null,
  status: StoreStatus.IDLE,
  response: null,
  dispatchErrors: null,
  dispatchStatus: StoreStatus.IDLE,
  dispatchResponse: null,
  manifestErrors: null,
  manifestStatus: StoreStatus.IDLE,
  manifestResponse: null,
  stagedManifestErrors: null,
  stagedManifestStatus: StoreStatus.IDLE,
  stagedManifestResponse: null,
};

// Slice
export const shippingSlice = createSlice({
  name: 'shipping',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(updateShippingThunk.pending.type, (state: ShippingState) => {
      state.status = StoreStatus.BUSY;
      state.errors = null;
    });

    builder.addCase(updateShippingThunk.fulfilled.type, (state: ShippingState, action: any) => {
      state.status = StoreStatus.IDLE;
      state.response = action.payload;
      state.errors = null;
    });

    builder.addCase(updateShippingThunk.rejected.type, (state: ShippingState, { payload }: any) => {
      state.status = StoreStatus.ERROR;
      state.errors = payload;
    });

    builder.addCase(getShippingThunk.pending.type, (state: ShippingState) => {
      state.status = StoreStatus.BUSY;
      state.errors = null;
    });

    builder.addCase(getShippingThunk.fulfilled.type, (state: ShippingState, action: any) => {
      state.status = StoreStatus.IDLE;
      state.response = action.payload;
      state.errors = null;
    });

    builder.addCase(getShippingThunk.rejected.type, (state: ShippingState, { payload }: any) => {
      state.status = StoreStatus.ERROR;
      state.errors = payload;
    });

    builder.addCase(dispatchShipmentThunk.pending.type, (state: ShippingState) => {
      state.dispatchStatus = StoreStatus.BUSY;
      state.dispatchErrors = null;
    });

    builder.addCase(dispatchShipmentThunk.fulfilled.type, (state: ShippingState, action: any) => {
      state.dispatchStatus = StoreStatus.IDLE;
      state.dispatchResponse = action.payload;
      state.dispatchErrors = null;
    });

    builder.addCase(dispatchShipmentThunk.rejected.type, (state: ShippingState, { payload }: any) => {
      state.dispatchStatus = StoreStatus.ERROR;
      state.dispatchErrors = payload;
      state.dispatchResponse = null;
    });

    builder.addCase(getShipmentManifestThunk.pending.type, (state: ShippingState) => {
      state.manifestStatus = StoreStatus.BUSY;
      state.manifestErrors = null;
      state.manifestResponse = null;
    });

    builder.addCase(getShipmentManifestThunk.fulfilled.type, (state: ShippingState, action: any) => {
      state.manifestStatus = StoreStatus.IDLE;
      state.manifestResponse = action.payload;
      state.manifestErrors = null;
    });

    builder.addCase(getShipmentManifestThunk.rejected.type, (state: ShippingState, { payload }: any) => {
      state.manifestStatus = StoreStatus.ERROR;
      state.manifestErrors = payload;
      state.manifestResponse = null;
    });
    builder.addCase(getStagedManifestThunk.pending.type, (state: ShippingState) => {
      state.stagedManifestStatus = StoreStatus.BUSY;
      state.stagedManifestErrors = null;
      state.stagedManifestResponse = null;
    });

    builder.addCase(getStagedManifestThunk.fulfilled.type, (state: ShippingState, action: any) => {
      state.stagedManifestStatus = StoreStatus.IDLE;
      state.stagedManifestResponse = action.payload;
      state.stagedManifestErrors = null;
    });

    builder.addCase(getStagedManifestThunk.rejected.type, (state: ShippingState, { payload }: any) => {
      state.stagedManifestStatus = StoreStatus.ERROR;
      state.stagedManifestErrors = payload;
      state.stagedManifestResponse = null;
    });
  },
});

// Action creators
export const { reset } = shippingSlice.actions;
export * from './thunks';
export * from './selectors';
export * from './types';
export default shippingSlice.reducer;
