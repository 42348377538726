import { createSlice } from '@reduxjs/toolkit';
import { DispatchState } from './types';
import { StoreStatus } from '../../core/types/common.types';
import { dispatchSampleThunk, getCountThunk } from './thunks';

// Initial State
const initialState: DispatchState = {
  errors: null,
  status: StoreStatus.IDLE,
  response: null,
  countErrors: null,
  countStatus: StoreStatus.IDLE,
  countResponse: null,
  sessionList: [],
};

// Slice
export const dispatchSlice = createSlice({
  name: 'dispatch',
  initialState,
  reducers: {
    clear: (state) => ({
      ...initialState,
      sessionList: state.sessionList,
      countResponse: state.countResponse,
    }),
    reset: () => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(dispatchSampleThunk.pending.type, (state) => {
      state.status = StoreStatus.BUSY;
    });

    builder.addCase(dispatchSampleThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatus.IDLE;
      state.response = action.payload;
      const orderNum = action.meta.arg.qrCode.match(/\d{4,}$/g)[0]; // extracts order number from qr code
      state.sessionList.unshift({
        sampleBarcode: action.meta.arg.sampleBarcode,
        orderNum,
      });
      state.errors = null;
    });

    builder.addCase(dispatchSampleThunk.rejected.type, (state, { payload }: any) => {
      state.status = StoreStatus.ERROR;
      state.errors = payload;
      state.response = null;
    });

    builder.addCase(getCountThunk.pending.type, (state) => {
      state.countStatus = StoreStatus.BUSY;
    });

    builder.addCase(getCountThunk.fulfilled.type, (state, action: any) => {
      state.countStatus = StoreStatus.IDLE;
      state.countResponse = action.payload;
      state.countErrors = null;
    });
    builder.addCase(getCountThunk.rejected.type, (state, { payload }: any) => {
      state.countStatus = StoreStatus.ERROR;
      state.countErrors = payload;
      state.countResponse = null;
    });
  },
});

// Action creators
export const { reset, clear } = dispatchSlice.actions;
export * from './thunks';
export * from './selectors';
export * from './types';
export default dispatchSlice.reducer;
