import { createSlice } from '@reduxjs/toolkit';
import { SampleSearchState } from './types';
import { StoreStatus } from '../../core/types/common.types';
import { sampleSearchThunk } from './thunks';

// Initial State
const initialState: SampleSearchState = {
  errors: null,
  status: StoreStatus.IDLE,
  response: null,
};

// Slice
export const sampleSearchSlice = createSlice({
  name: 'sampleSearch',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(sampleSearchThunk.pending.type, (state) => {
      state.status = StoreStatus.BUSY;
    });

    builder.addCase(sampleSearchThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatus.IDLE;
      state.response = {
        ...action.meta.arg,
        ...action.payload,
      };
      state.errors = null;
    });

    builder.addCase(sampleSearchThunk.rejected.type, (state, { payload }: any) => {
      state.status = StoreStatus.ERROR;
      state.errors = payload;
      state.response = null;
    });
  },
});

// Action creators
export const { reset } = sampleSearchSlice.actions;
export * from './thunks';
export * from './selectors';
export * from './types';
export default sampleSearchSlice.reducer;
