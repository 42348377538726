import MainLayout from '../../../../../../components/layouts/MainLayout';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import { AiOutlineSearch } from 'react-icons/ai';
import { RiLockFill } from 'react-icons/ri';
import { FormInput, Message } from '@omnigenbiodata/ui';
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import { DataGrid, GridAlignment } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import ROUTES from '../../../../../../core/constants/routes.constants';
import {
  boxesIsBusySelector,
  boxesErrorSelector,
  boxesResponseSelector,
  exportIsBusySelector,
  exportResponseSelector,
  exportErrorSelector,
} from '../../../../../../store/storage/selectors';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import { MESSAGES } from '../../../../../../core/constants/forms.constants';
import Buttons from '../../../../../../components/forms/Buttons';
import ScanEvent from '../../../../../../components/forms/ScanEvent';
import { getSampleExportThunk, getStorageBoxesThunk } from '../../../../../../store/storage/thunks';
import { clearExport } from '../../../../../../store/storage';
import moment from 'moment';

const shelfComparator = (v1: string, v2: string) => Number(v1) - Number(v2);

const columns = () => [
  {
    field: 'boxBarcode',
    headerName: 'Box',
    flex: 1,
  },
  {
    field: 'shelfPosition',
    headerName: 'Shelf',
    flex: 1,
    sortComparator: shelfComparator,
  },
  {
    field: 'createdTs',
    headerName: 'Created On',
    valueFormatter: (params: any) => moment(params.value).format('DD-MM-YY h:mma'),
    flex: 1,
  },
  {
    field: 'samples',
    headerName: 'Samples',
    flex: 0.5,
    align: 'center' as GridAlignment,
    valueFormatter: (params: any) => {
      const samples = params.value.filter((value: any) => value?.sampleBarcode);
      return `${samples.length}/81`;
    },
  },
  {
    field: 'locked',
    headerName: '',
    align: 'right' as GridAlignment,
    flex: 0.5,
    renderCell: (params: any) =>
      params.value ? (
        <Tooltip
          arrow
          title={
            <Box p={2} alignItems="middle" display="flex">
              <Typography variant="body1" component="span" justifyContent="center">
                {MESSAGES.boxLocked}
              </Typography>
            </Box>
          }
          data-testid={`box-locked`}
          placement="top"
        >
          <div>
            <RiLockFill fontSize={20} />
          </div>
        </Tooltip>
      ) : (
        <Link to={ROUTES.storageEdit.replace(':boxBarcode', params.id)}>Edit</Link>
      ),
  },
];

function ManageScene() {
  const { t } = useTranslation('portal');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const errors = useAppSelector(boxesErrorSelector);
  const list = useAppSelector(boxesResponseSelector);
  const isBusy = useAppSelector(boxesIsBusySelector);
  const exportLoading = useAppSelector(exportIsBusySelector);
  const exportResponse = useAppSelector(exportResponseSelector);
  const exportError = useAppSelector(exportErrorSelector);

  useEffect(() => {
    dispatch(getStorageBoxesThunk(''));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearExport());
    };
  }, [dispatch]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      searchTerm: '',
    },
    onSubmit: (values) => {
      dispatch(getStorageBoxesThunk(values.searchTerm));
    },
  });

  const handleScan = (scanCode: string) => formik.setFieldValue('searchTerm', scanCode);

  const goCreate = () => {
    navigate(ROUTES.storageCreate);
  };

  const createExport = () => {
    if (!exportLoading) {
      dispatch(getSampleExportThunk());
    }
  };

  const downloadExport = () => {
    if (exportResponse?.exportLink) {
      window.open(exportResponse.exportLink, '_blank');
    }
  };

  return (
    <MainLayout title={t('manageStore.title')}>
      <Container maxWidth="sm" sx={{ paddingX: 0, paddingTop: 4 }}>
        <form onSubmit={formik.handleSubmit}>
          <ScanEvent data-testid="scanner" onScan={handleScan} />
          <Grid container spacing={2} mb={2}>
            <Grid item xs>
              <FormInput
                label={t('forms.search')}
                type="text"
                name={`searchTerm`}
                data-testid="searchTerm"
                error={formik.errors.searchTerm}
                touched={formik.touched.searchTerm}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.searchTerm}
                margin="none"
                startAdornment={<AiOutlineSearch fontSize={25} />}
              />
            </Grid>
            <Grid item xs="auto">
              <Button type="submit" variant="outlined" sx={{ height: '100%' }} size="large" fullWidth>
                {t('buttons.search')}
              </Button>
            </Grid>
          </Grid>
        </form>
        {errors && (
          <Message severity="error" title={t('alerts.manageStorageFailed.title') as string}>
            {errors[0].message}
          </Message>
        )}
        {exportError && (
          <Message severity="error" title={t('alerts.manageStorageFailed.title') as string}>
            {exportError[0].message}
          </Message>
        )}
      </Container>
      <Box display="flex">
        <Button
          sx={{
            marginLeft: 'auto',
            paddingX: 6,
            minWidth: '210px',
            minHeight: '42px',
          }}
          size="large"
          variant="contained"
          onClick={exportResponse?.exportLink ? downloadExport : createExport}
        >
          {exportLoading ? (
            <CircularProgress data-testid="export-loader" size={20} sx={{ color: 'white' }} />
          ) : exportResponse?.exportLink ? (
            t('buttons.downloadExport')
          ) : (
            t('buttons.createExport')
          )}
        </Button>
      </Box>
      <Box mb={4} mt={2} sx={{ bgcolor: 'background.paper' }}>
        <DataGrid
          loading={isBusy}
          rows={list || []}
          autoHeight
          getRowId={(row) => row.boxBarcode}
          columns={columns()}
          pageSizeOptions={[10, 25, 50]}
          initialState={{ pagination: { paginationModel: { pageSize: 50 } } }}
        />
      </Box>
      <Buttons forwardLabel={t('buttons.createNew')} onForward={goCreate} />
    </MainLayout>
  );
}

export default ManageScene;
