import MainLayout from '../../../../components/layouts/MainLayout';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FormInput, theme, FormSelect, Message } from '@omnigenbiodata/ui';
import { FaUndo } from 'react-icons/fa';
import { RiBarcodeLine } from 'react-icons/ri';
import { BsQrCode } from 'react-icons/bs';
import { useAppDispatch, useAppSelector } from '../../../../store';
import ScanEvent from '../../../../components/forms/ScanEvent';
import { CONDITION_OPTIONS, MESSAGES } from '../../../../core/constants/forms.constants';
import {
  isBusySelector,
  errorSelector,
  hasSuccessSelector,
  listSelector,
  clear,
  reset,
  receiveSampleThunk,
} from '../../../../store/receive';
import { INCOMING_QR_REGEX, OUTGOING_QR_REGEX, BARCODE_REGEX } from '../../../../core/constants/forms.constants';
import SuccessList from '../../../../components/SuccessList';
import { useTranslation } from 'react-i18next';
import ROUTES from '../../../../core/constants/routes.constants';
import Buttons from '../../../../components/forms/Buttons';
import { OrderCondition } from '../../../../core/types/common.types';

const wrongQR = (code: string) => new RegExp(OUTGOING_QR_REGEX, 'g').test(code);

const validationSchema = yup.object().shape({
  qrCode: yup
    .string()
    .required(MESSAGES.qrRequired)
    .test('wrongQR', MESSAGES.wrongQR, (value) => !wrongQR(value!))
    .matches(new RegExp(INCOMING_QR_REGEX, 'g'), MESSAGES.qrInvalid),
  sampleBarcode: yup.string().when('condition', (condition, schema) => {
    if (condition === 'Viable')
      return schema.required(MESSAGES.barcodeRequired).matches(BARCODE_REGEX, MESSAGES.barcodeInvalid);
  }),
  condition: yup.string().oneOf(Object.keys(OrderCondition)),
});

function ReceiveScene() {
  const { t } = useTranslation('portal');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isBusy = useAppSelector(isBusySelector);
  const hasSuccess = useAppSelector(hasSuccessSelector);
  const errors = useAppSelector(errorSelector);
  const list = useAppSelector(listSelector);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      qrCode: '',
      sampleBarcode: '',
      condition: 'Viable',
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: () => {
      dispatch(
        receiveSampleThunk({
          qrCode: formik.values.qrCode,
          viable: formik.values.condition === 'Viable',
          ...(formik.values.sampleBarcode && {
            sampleBarcode: formik.values.sampleBarcode,
          }),
        }),
      );
    },
  });

  const handleScan = (code: string) => {
    if (!formik.values.qrCode) {
      formik.setFieldValue('qrCode', code);
    } else {
      formik.setFieldValue('sampleBarcode', code);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  useEffect(() => {
    if (hasSuccess) {
      formik.resetForm();
      dispatch(clear());
    }
  }, [hasSuccess, formik, dispatch]);

  const clearForm = () => {
    formik.resetForm();
    dispatch(clear());
  };

  const goBack = () => navigate(ROUTES.root);

  return (
    <MainLayout title={t('receive.title')}>
      <form onSubmit={formik.handleSubmit}>
        <Container maxWidth="sm">
          <Typography variant="body1" align="center" p={2}>
            {t('receive.text')}
          </Typography>
          {errors && (
            <Message severity="error" onClose={clearForm} title={t('alerts.receiveFailed.title') as string}>
              {errors[0].message}
            </Message>
          )}
          <ScanEvent
            data-testid="scanner"
            onScan={(scanCode: string) => {
              if (scanCode) {
                handleScan(scanCode);
              }
            }}
          />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormInput
                label={t('forms.qrCode')}
                type="text"
                name={`qrCode`}
                showErrorMessage={true}
                error={formik.errors.qrCode}
                onChange={formik.handleChange}
                onBlur={() => undefined}
                value={formik.values.qrCode}
                touched={formik.touched.qrCode}
                readOnly
                startAdornment={<BsQrCode fontSize={20} color={theme.palette.grey[500]} />}
                endAdornment={
                  formik.values.qrCode && (
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => {
                        formik.setFieldValue('qrCode', '');
                        dispatch(clear());
                      }}
                    >
                      <FaUndo fontSize={20} color={theme.palette.error.dark} />
                    </IconButton>
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInput
                label={t('forms.barcode')}
                type="text"
                name={`sampleBarcode`}
                error={formik.errors.sampleBarcode}
                touched={formik.touched.sampleBarcode}
                onChange={formik.handleChange}
                onBlur={() => undefined}
                value={formik.values.sampleBarcode}
                readOnly
                disabled={!formik.values?.sampleBarcode}
                startAdornment={<RiBarcodeLine fontSize={25} color={theme.palette.grey[500]} />}
                endAdornment={
                  formik.values.sampleBarcode && (
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => {
                        formik.setFieldValue('sampleBarcode', '');
                        dispatch(clear());
                      }}
                    >
                      <FaUndo fontSize={20} color={theme.palette.error.dark} />
                    </IconButton>
                  )
                }
              />
            </Grid>
          </Grid>
          <FormSelect
            error={formik.errors.condition}
            name="condition"
            label={t('forms.orderCondition')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            options={CONDITION_OPTIONS}
            placeholder=""
            touched={formik.touched.condition}
            value={formik.values.condition}
          />
          <SuccessList list={list} loading={isBusy} />
          <Buttons
            backLabel={t('buttons.finish') as string}
            onBack={goBack}
            forwardLabel={t('buttons.confirm')}
            onForward={formik.handleSubmit}
          />
        </Container>
      </form>
    </MainLayout>
  );
}

export default ReceiveScene;
