import { SelectOption } from '@omnigenbiodata/ui';
import { OrderCondition, OrderStatus } from '../types/common.types';

/* Validation Rules */
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

const OMNIGEN_POSTCODE = 'CB13NA';

export const OUTGOING_QR_REGEX = `^(JGB6202)\\w+(${OMNIGEN_POSTCODE}\\w+\\d{4,})$`;
export const INCOMING_QR_REGEX = `^(JGB6209)\\w+(${OMNIGEN_POSTCODE}\\w+\\d{4,})$`;

export const SHELF_REGEX = /^(\d{1,})$/g;
export const BARCODE_REGEX = /^(\d{13}|\w{10})$/g;
export const STORAGE_BOX_REGEX = /^(\d{2,}OMNI\d{4,})/g;

export const STORAGE_BOX_WIDTH = 9;

export const MESSAGES = {
  emailInvalid: 'Invalid E-mail address',
  emailRequired: 'E-mail is required',
  firstNameRequired: 'First Name is required',
  lastNameRequired: 'Surname is required',
  idRequired: 'Participant ID is required',
  otpRequired: 'One-time password is required',
  passwordConfirmRequired: 'Confirm new password is required',
  passwordRequired: 'Password is required',
  passwordFormat:
    'Must contain at least 8 characters, one uppercase, one lowercase, one number and one special character',
  passwordNoMatch: "Passwords don't match",
  barcodeRequired: 'Barcode is required',
  shelfRequired: 'Shelf position is required',
  barcodeInvalid: 'Invalid Sample Barcode',
  storageBoxInvalid: 'Invalid sample box barcode, format must be 01OMNI0001',
  hasWithdrawals: 'Box contains samples from withdrawn participants. Please remove before proceeding.',
  shelfInvalid: 'Shelf position must be a number',
  orderRequired: 'Order number is required',
  qrRequired: 'QR code is required',
  qrInvalid: 'Invalid QR Code',
  wrongQR: "Looks like you've scanned the return label. Please make sure to scan the outgoing label.",
  boxLocked: 'Box is currently locked. Please remove from the shipping staging area if you wish to edit.',
};

export const CONDITION_OPTIONS: SelectOption[] = Object.entries(OrderCondition).map(([key, value]) => ({
  label: value,
  value: key,
}));

export const STATUS_OPTIONS: SelectOption[] = Object.entries(OrderStatus).map(([key, value]) => ({
  label: value,
  value: key,
}));

const FORMS = {
  PASSWORD_REGEX,
  MESSAGES,
};
export default FORMS;
