import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { RiBarcodeLine } from 'react-icons/ri';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import { FormInput, theme } from '@omnigenbiodata/ui';
import { MESSAGES, STORAGE_BOX_WIDTH } from '../../../../../../../../core/constants/forms.constants';
import ScanEvent from '../../../../../../../../components/forms/ScanEvent';
import { SamplePosition } from '../../../../../../../../core/types/common.types';

interface EditSampleDialogProps {
  index: number;
  isOpen?: boolean;
  value: SamplePosition;
  onClose: () => void;
  onSave: (value: string, index: number) => void;
  onRemove: (index: number) => void;
}

function EditSampleDialog({ index, isOpen = false, onClose, onSave, onRemove, value }: EditSampleDialogProps) {
  const validationSchema = yup.lazy(() =>
    yup.object({
      newSample: yup.string().required(MESSAGES.barcodeRequired),
    }),
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      newSample: value?.sampleBarcode || '',
      index,
    },
    validationSchema,
    onSubmit: (values) => {
      onSave(values.newSample, index);
      onClose();
    },
  });

  const col = index % STORAGE_BOX_WIDTH;

  /* row number + 65 (unicode for A) to get row letter + column number */
  const label = String.fromCharCode((index - col) / STORAGE_BOX_WIDTH + 65) + ((index % STORAGE_BOX_WIDTH) + 1);

  return (
    <Dialog onClose={onClose} aria-labelledby="sample-box-dialog" data-testid="sample-box-dialog" open={isOpen}>
      {index !== undefined && <DialogTitle id="sample-box-dialog-title">Sample Position {label}</DialogTitle>}
      <DialogContent>
        <FormikProvider value={formik}>
          <Form>
            <ScanEvent
              data-testid="scanner"
              onScan={(scanCode: string) => {
                if (scanCode && index !== undefined) {
                  formik.setFieldValue('newSample', scanCode);
                }
              }}
            />
            <Typography variant="body1" component="p">
              Scan a new barcode to replace the current sample
            </Typography>
            <Box mb={2}>
              <FormInput
                label="New Sample Barcode"
                type="text"
                name={`newSample`}
                error={formik.errors.newSample}
                touched={formik.touched.newSample}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.newSample}
                readOnly
                startAdornment={<RiBarcodeLine fontSize={25} color={theme.palette.grey[500]} />}
              />
            </Box>
            <Box mb={1} display="flex" justifyContent="space-between">
              <Button variant="contained" fullWidth color="success" size="large" onClick={() => formik.handleSubmit()}>
                Update
              </Button>
              {value && (
                <Button
                  color="error"
                  size="large"
                  variant="contained"
                  fullWidth
                  type="button"
                  sx={{ ml: 2 }}
                  onClick={() => {
                    onRemove(index);
                  }}
                >
                  Delete
                </Button>
              )}
            </Box>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}

export default EditSampleDialog;
