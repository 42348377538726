import { useState, useEffect } from 'react';
import MainLayout from '../../../../../../components/layouts/MainLayout';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useFormik, FieldArray, Form, FormikProvider } from 'formik';
import { FormInput, Loader, theme, Message } from '@omnigenbiodata/ui';
import { FaUndo } from 'react-icons/fa';
import { RiBarcodeLine } from 'react-icons/ri';
import ScanEvent from '../../../../../../components/forms/ScanEvent';
import { MESSAGES, SHELF_REGEX, STORAGE_BOX_REGEX } from '../../../../../../core/constants/forms.constants';
import * as yup from 'yup';
import Buttons from '../../../../../../components/forms/Buttons';
import { useTranslation } from 'react-i18next';
import { SamplePosition, StorageBox } from '../../../../../../core/types/common.types';
import SampleGrid from './components/SampleGrid';
import { createStorageThunk, getStorageBoxThunk, reset } from '../../../../../../store/storage';
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import {
  hasSuccessSelecter,
  isBusySelector,
  errorSelector,
  boxIsBusySelector,
  boxResponseSelector,
} from '../../../../../../store/storage/selectors';
import { BARCODE_REGEX } from '../../../../../../core/constants/forms.constants';
import ROUTES from '../../../../../../core/constants/routes.constants';
import { updateShippingThunk } from '../../../../../../store/shipping';

const SampleSchema = yup.lazy((value) => {
  if (value) {
    return yup
      .object()
      .shape({
        sampleBarcode: yup.string().required(MESSAGES.barcodeRequired).matches(BARCODE_REGEX, MESSAGES.barcodeInvalid),
      })
      .required();
  }
}) as any;

const validationSchema = yup.object().shape({
  boxBarcode: yup.string().required(MESSAGES.barcodeRequired).matches(STORAGE_BOX_REGEX, MESSAGES.storageBoxInvalid),
  shelfPosition: yup.string().required(MESSAGES.shelfRequired).matches(new RegExp(SHELF_REGEX), MESSAGES.shelfInvalid),
  sample: yup.array().of(SampleSchema),
});

function CreateScene() {
  const { t } = useTranslation('portal');
  const { boxBarcode } = useParams<any>();
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isBusy = useAppSelector(isBusySelector);
  const boxIsBusy = useAppSelector(boxIsBusySelector);
  const box = useAppSelector(boxResponseSelector);
  const errors = useAppSelector(errorSelector);
  const hasSuccess = useAppSelector(hasSuccessSelecter);
  const [submissionError, setSubmissionError] = useState<string | null>();
  const [selectedSample, setSelected] = useState<number | undefined>();
  const emptySpaces = box ? 81 - box.samples.length : 81;
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      boxBarcode: box ? box.boxBarcode : '',
      shelfPosition: box ? box.shelfPosition : '',
      samples: box ? [...box.samples, ...Array(emptySpaces)] : (Array(81) as SamplePosition[]),
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: (values: StorageBox) => {
      if (state?.shipping && values.samples.some((samples) => samples?.participantWithdrawn)) {
        setSubmissionError(MESSAGES.hasWithdrawals);
      } else {
        dispatch(createStorageThunk({ ...values, isEdit: !!boxBarcode }));
      }
    },
  });

  useEffect(() => {
    if (boxBarcode && !box) {
      dispatch(getStorageBoxThunk(boxBarcode));
    }
  }, [boxBarcode, dispatch, box]);

  useEffect(() => {
    if (hasSuccess) {
      if (state?.shipping && box) {
        dispatch(
          updateShippingThunk({
            boxBarcode: box.boxBarcode,
            operation: 'add',
          }),
        );
        navigate(ROUTES.shipping);
      } else navigate(ROUTES.storageManage);
    }
  }, [hasSuccess, navigate, state, box, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  const handleScan = (scanCode: string) => {
    if (scanCode) {
      formik.setFieldValue('boxBarcode', scanCode);
    }
  };

  return (
    <MainLayout title={t('createStore.title')}>
      <FormikProvider value={formik}>
        <Form>
          <Container maxWidth="sm">
            <Typography variant="body1" align="center" mb={2}>
              {boxBarcode ? t('createStore.editText') : t('createStore.createText')}
            </Typography>
            {errors && (
              <Message severity="error" title={t('alerts.saveStorageFailed.title') as string}>
                {errors[0].message}
              </Message>
            )}
            {submissionError && (
              <Message severity="error" onClose={() => setSubmissionError(null)}>
                {submissionError}
              </Message>
            )}
            {!formik.values.boxBarcode && selectedSample === undefined && (
              <ScanEvent data-testid="scanner" onScan={handleScan} />
            )}
            <Grid pb={2} container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormInput
                  label={t('forms.boxBarcode')}
                  type="text"
                  name={`boxBarcode`}
                  error={formik.errors.boxBarcode}
                  touched={formik.touched.boxBarcode}
                  onChange={formik.handleChange}
                  onBlur={() => undefined}
                  value={formik.values.boxBarcode}
                  readOnly
                  startAdornment={<RiBarcodeLine fontSize={25} color={theme.palette.grey[500]} />}
                  endAdornment={
                    formik.values.boxBarcode &&
                    !boxBarcode && (
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => {
                          formik.setFieldValue('boxBarcode', '');
                        }}
                      >
                        <FaUndo fontSize={20} color={theme.palette.error.dark} />
                      </IconButton>
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormInput
                  label={t('forms.shelfPosition')}
                  type="text"
                  name={`shelfPosition`}
                  error={formik.errors.shelfPosition}
                  touched={formik.touched.shelfPosition}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.shelfPosition}
                />
              </Grid>
            </Grid>
            <FieldArray
              name="samples"
              render={({ replace }) => (
                <SampleGrid
                  canScan={!!formik.values.shelfPosition && !!formik.values.boxBarcode}
                  values={formik.values.samples}
                  replace={replace}
                  onSelect={setSelected}
                  editMode={!!boxBarcode}
                  errors={formik.errors.samples}
                  selectedSample={selectedSample}
                />
              )}
            />
            <Buttons
              forwardLabel={boxBarcode ? t('buttons.save') : t('buttons.create')}
              onForward={formik.handleSubmit}
              backButton
            />
          </Container>
        </Form>
      </FormikProvider>
      <Loader isVisible={isBusy || boxIsBusy} label={isBusy ? (t('loaders.storage') as string) : undefined} />
    </MainLayout>
  );
}

export default CreateScene;
