import MainLayout from '../../../../../../components/layouts/MainLayout';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { FaTrashAlt } from 'react-icons/fa';
import { RiBarcodeLine } from 'react-icons/ri';
import { FormInput, Message, Loader, theme } from '@omnigenbiodata/ui';
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import { DataGrid, GridAlignment } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import ROUTES from '../../../../../../core/constants/routes.constants';
import { isBusySelector, errorSelector, responseSelector } from '../../../../../../store/shipping/selectors';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { FaUndo } from 'react-icons/fa';
import { useFormik } from 'formik';
import Buttons from '../../../../../../components/forms/Buttons';
import CircularProgress from '@mui/material/CircularProgress';
import ScanEvent from '../../../../../../components/forms/ScanEvent';
import {
  getShippingThunk,
  updateShippingThunk,
  getStagedManifestThunk,
  stagedManifestResponseSelector,
  stagedManifestIsBusySelector,
  dispatchErrorSelector,
  dispatchResponseSelector,
  dispatchIsBusySelector,
} from '../../../../../../store/shipping';
import {
  boxResponseSelector,
  boxErrorSelector,
  boxIsBusySelector,
  getStorageBoxThunk,
  reset,
} from '../../../../../../store/storage';
import ConfirmationModal from './components/ConfirmationModal';
import { CSVLink } from 'react-csv';

const shelfComparator = (v1: string, v2: string) => Number(v1) - Number(v2);

const columns = (deleteBox: (boxBarcode: string) => void) => [
  {
    field: 'barcode',
    headerName: 'Box',
    flex: 1,
  },
  {
    field: 'shelfPosition',
    headerName: 'Shelf',
    flex: 1,
    sortComparator: shelfComparator,
  },
  {
    field: 'count',
    headerName: 'Samples',
    flex: 0.5,
    align: 'center' as GridAlignment,
    headerAlign: 'center' as GridAlignment,
  },
  {
    field: 'delete',
    headerName: '',
    align: 'right' as GridAlignment,
    flex: 0.5,
    renderCell: ({ id }: any) => (
      <IconButton
        aria-label="Delete Box"
        data-testid={`delete-box-${id}`}
        size="small"
        onClick={() => {
          deleteBox(id);
        }}
      >
        <FaTrashAlt fontSize={20} />
      </IconButton>
    ),
  },
];

function CreateShipmentScene() {
  const { t } = useTranslation('portal');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const errors = useAppSelector(errorSelector);
  const staged = useAppSelector(responseSelector);
  const isBusy = useAppSelector(isBusySelector);
  const box = useAppSelector(boxResponseSelector);
  const boxError = useAppSelector(boxErrorSelector);
  const boxBusy = useAppSelector(boxIsBusySelector);
  const dispatchError = useAppSelector(dispatchErrorSelector);
  const dispatchIsBusy = useAppSelector(dispatchIsBusySelector);
  const dispatchResponse = useAppSelector(dispatchResponseSelector);
  const manifest = useAppSelector(stagedManifestResponseSelector);
  const manifestLoading = useAppSelector(stagedManifestIsBusySelector);
  const [showConfirm, setConfirm] = useState(false);
  const manifestDownload = useRef<any>(null);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      boxBarcode: '',
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    dispatch(getShippingThunk());
  }, [dispatch]);

  useEffect(() => {
    if (box && formik.values.boxBarcode) {
      if (box.samples.some((sample) => sample.participantWithdrawn)) {
        navigate(ROUTES.storageEdit.replace(':boxBarcode', box.boxBarcode), {
          state: { shipping: 'new' },
        });
      } else {
        dispatch(updateShippingThunk({ boxBarcode: box.boxBarcode, operation: 'add' }));
        dispatch(reset());
      }
      formik.setFieldValue('boxBarcode', '');
    }
  }, [box, dispatch, navigate, formik]);

  useEffect(() => {
    if (dispatchResponse) {
      navigate(ROUTES.shippingSuccess);
    }
  }, [dispatchResponse, navigate]);

  useEffect(() => {
    if (manifest) {
      manifestDownload.current.link.click();
    }
  }, [manifest]);

  const deleteBox = (boxBarcode: string) => {
    dispatch(updateShippingThunk({ boxBarcode: boxBarcode, operation: 'remove' }));
  };

  const downloadManifest = () => {
    if (staged?.sampleBoxes?.length) {
      dispatch(getStagedManifestThunk());
    }
  };

  const handleScan = (boxBarcode: string) => {
    formik.setFieldValue('boxBarcode', boxBarcode);
    dispatch(getStorageBoxThunk(boxBarcode));
  };

  const handleConfirm = () => {
    if (staged?.sampleBoxes?.length) {
      setConfirm(true);
    }
  };

  return (
    <MainLayout title={t('createShipment.title')}>
      <Container maxWidth="sm">
        <Typography mb={2} variant="body1" align="center">
          {t('createShipment.text')}
        </Typography>
        {manifest && (
          <CSVLink
            filename="DMUK Draft Manifest.csv"
            data={manifest}
            target="_blank"
            data-testid="draft-manifest-download"
            ref={manifestDownload}
          />
        )}
        <form onSubmit={formik.handleSubmit}>
          <ScanEvent data-testid="scanner" onScan={handleScan} />
          <Grid container py={2} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <FormInput
                label={t('forms.boxBarcode')}
                type="text"
                name={`boxBarcode`}
                data-testid="boxBarcode"
                error={formik.errors.boxBarcode}
                touched={formik.touched.boxBarcode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.boxBarcode}
                readOnly
                disabled={!formik.values?.boxBarcode}
                margin="none"
                startAdornment={<RiBarcodeLine fontSize={25} />}
                endAdornment={
                  formik.values.boxBarcode && (
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => {
                        formik.setFieldValue('boxBarcode', '');
                      }}
                    >
                      <FaUndo fontSize={20} color={theme.palette.error.dark} />
                    </IconButton>
                  )
                }
              />
            </Grid>
          </Grid>
        </form>
        {errors && <Message severity="error">{errors[0].message}</Message>}
        {dispatchError && <Message severity="error">{dispatchError[0].message}</Message>}
        {boxError && <Message severity="error">{boxError[0].message}</Message>}
      </Container>
      <Button
        size="large"
        sx={{
          marginLeft: 'auto',
          paddingX: 6,
          minWidth: '210px',
          minHeight: '42px',
        }}
        disabled={!staged?.sampleBoxes?.length}
        variant="contained"
        onClick={downloadManifest}
      >
        {manifestLoading ? (
          <CircularProgress data-testid="export-loader" size={20} sx={{ color: 'white' }} />
        ) : (
          t('buttons.downloadManifest')
        )}
      </Button>
      <Box mb={4} mt={2} sx={{ bgcolor: 'background.paper' }}>
        <DataGrid
          loading={isBusy || boxBusy}
          rows={staged?.sampleBoxes || []}
          autoHeight
          getRowId={(row) => row.barcode}
          columns={columns(deleteBox)}
          pageSizeOptions={[10, 25, 50]}
          initialState={{ pagination: { paginationModel: { pageSize: 50 } } }}
        />
      </Box>
      <Buttons isDisabled={!staged?.sampleBoxes?.length} forwardLabel={t('buttons.ship')} onForward={handleConfirm} />
      <ConfirmationModal isOpen={showConfirm} onClose={() => setConfirm(false)} staged={staged} t={t} />
      <Loader isVisible={dispatchIsBusy} label={t('loaders.shipping') as string} />
    </MainLayout>
  );
}

export default CreateShipmentScene;
