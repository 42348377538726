import { RootState } from '../index';
import { StoreStatus } from '../../core/types/common.types';

export const errorSelector = (state: RootState): any | null => state.dispatch.errors || null;

export const listSelector = (state: RootState): any | null => state.dispatch.sessionList;

export const isBusySelector = (state: RootState): boolean => state.dispatch.status === StoreStatus.BUSY;

export const responseSelector = (state: RootState): string => state.dispatch.response || null;

export const hasSuccessSelector = (state: RootState): any | null => (state.dispatch.response ? true : false);

export const countSelector = (state: RootState): string => state.dispatch.countResponse?.count;

export const countIsBusySelector = (state: RootState): boolean => state.dispatch.countStatus === StoreStatus.BUSY;

export const countErrorSelector = (state: RootState): any | null => state.dispatch.countErrors || null;
