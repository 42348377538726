import { RootState } from '../index';
import { StoreStatus, Participant } from '../../core/types/common.types';

export const errorSelector = (state: RootState): any | null => state.print.errors || state.print.printErrors || null;

export const isBusySelector = (state: RootState): boolean => state.print.status === StoreStatus.BUSY;

export const isPrintingSelector = (state: RootState): boolean => state.print.printStatus === StoreStatus.BUSY;

export const participantsByPrintedStatusSelector = (state: RootState): Participant[] =>
  state.print.response && state.print.response['printed'] ? state.print.response['printed'] : [];

export const participantsByPendingStatusSelector = (state: RootState): Participant[] =>
  state.print.response && state.print.response['pending'] ? state.print.response['pending'] : [];

export const printResponseSelector = (state: RootState): string | null => state.print.printResponse?.pdf || null;

export const hasSuccessSelector = (state: RootState): boolean => (state.print.response ? true : false);

export const hasPrintSuccessSelector = (state: RootState): boolean => (state.print.printResponse ? true : false);

export const hasPrintErrorSelector = (state: RootState): boolean => (state.print.printErrors ? true : false);

export const printCountSelector = (state: RootState): number => state.print.printCount;
