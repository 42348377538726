import { useEffect } from 'react';
import { useAppDispatch } from '../../../../store';
import { reset } from '../../../../store/print';
import { Outlet } from 'react-router-dom';

function PrintScene() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  return <Outlet />;
}

export default PrintScene;
