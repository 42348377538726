import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../../store';
import { reset } from '../../../../store/shipping';
import { useAuth } from '@omnigenbiodata/ui';
import { Navigate } from 'react-router-dom';
import ROUTES from '../../../../core/constants/routes.constants';
import { isAdmin } from '../../../../core/utils/auth';

function ShippingScene() {
  const dispatch = useAppDispatch();
  const { user } = useAuth();

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  if (!isAdmin(user)) {
    return <Navigate to={ROUTES.root} />;
  }

  return <Outlet />;
}

export default ShippingScene;
