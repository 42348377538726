import { API as MOCK_AMPLIFY_API } from '../__mocks__/aws-amplify';
import { API as AMPLIFY_API } from 'aws-amplify';
import {
  dispatchSampleMutation,
  receiveSampleMutation,
  printLabelsMutation,
  createStorageBoxMutation,
  editStorageBoxMutation,
  requestSampleExport,
  updateShippingMutation,
  dispatchShipmentMutation,
} from '../graphql/mutations';
import {
  getParticipantsByStatusQuery,
  getStorageBoxesQuery,
  getOrderByOrderNumberQuery,
  getSampleExportStatusQuery,
  getDailyDispatchCountQuery,
  getStorageBoxQuery,
  getShippingQuery,
  getShipmentManifestQuery,
  getStagedManifestQuery,
  getShippingHistoryQuery,
} from '../graphql/queries';
import {
  GetParticipantsByStatusResponse,
  ReceiveSampleResponse,
  DispatchSampleResponse,
  PrintLabelsResponse,
  CreateStorageResponse,
  GetStorageBoxesResponse,
  StorageBox,
  GetOrderByOrderNumberResponse,
  SampleExportStatusResponse,
  RequestSampleResponse,
  DispatchCountResponse,
  GetStorageBoxResponse,
  GetShippingResponse,
  UpdateShippingResponse,
  DispatchShipmentResponse,
  GetShipmentManifestResponse,
  GetStagedManifestResponse,
  GetShippingHistoryResponse,
} from '../types/common.types';

const API = process.env.REACT_APP_USE_MOCKS === 'true' ? MOCK_AMPLIFY_API : AMPLIFY_API;

const portalApi = {
  getParticipantsByStatus: async (status: string): Promise<any> => {
    try {
      return (await API.graphql({
        query: getParticipantsByStatusQuery,
        variables: {
          status: status,
        },
      })) as GetParticipantsByStatusResponse;
    } catch (error) {
      return error as GetParticipantsByStatusResponse;
    }
  },
  getOrderByOrderNumber: async (values: { status: string; orderNo: string }): Promise<any> => {
    try {
      return (await API.graphql({
        query: getOrderByOrderNumberQuery,
        variables: values,
      })) as GetOrderByOrderNumberResponse;
    } catch (error) {
      return error as GetOrderByOrderNumberResponse;
    }
  },
  receiveSample: async (values: { qrCode: string; sampleBarcode?: string; viable: boolean }): Promise<any> => {
    try {
      return (await API.graphql({
        query: receiveSampleMutation,
        variables: {
          input: values,
        },
      })) as ReceiveSampleResponse;
    } catch (error) {
      return error as ReceiveSampleResponse;
    }
  },
  dispatchSample: async (values: { qrCode: string; sampleBarcode: string }): Promise<any> => {
    try {
      return (await API.graphql({
        query: dispatchSampleMutation,
        variables: {
          input: values,
        },
      })) as DispatchSampleResponse;
    } catch (error) {
      return error as DispatchSampleResponse;
    }
  },
  createStorage: async (values: StorageBox): Promise<any> => {
    try {
      if (values.isEdit) {
        return (await API.graphql({
          query: editStorageBoxMutation,
          variables: {
            input: {
              boxBarcode: values.boxBarcode,
              samples: values.samples.map((sample) =>
                sample
                  ? {
                      sampleBarcode: sample.sampleBarcode,
                    }
                  : null,
              ),
              shelfPosition: values.shelfPosition,
            },
          },
        })) as CreateStorageResponse;
      } else
        return (await API.graphql({
          query: createStorageBoxMutation,
          variables: {
            input: {
              boxBarcode: values.boxBarcode,
              samples: values.samples,
              shelfPosition: values.shelfPosition,
            },
          },
        })) as CreateStorageResponse;
    } catch (error) {
      return error as CreateStorageResponse;
    }
  },
  getStorageBoxes: async (searchTerm: string): Promise<any> => {
    try {
      return (await API.graphql({
        query: getStorageBoxesQuery,
        variables: {
          searchTerm: searchTerm,
        },
      })) as GetStorageBoxesResponse;
    } catch (error) {
      return error as GetStorageBoxesResponse;
    }
  },
  getShipmentManifest: async (shipmentId: string): Promise<any> => {
    try {
      return (await API.graphql({
        query: getShipmentManifestQuery,
        variables: {
          shipmentId: shipmentId,
        },
      })) as GetShipmentManifestResponse;
    } catch (error) {
      return error as GetShipmentManifestResponse;
    }
  },
  getStagedManifest: async (): Promise<any> => {
    try {
      return (await API.graphql({
        query: getStagedManifestQuery,
      })) as GetStagedManifestResponse;
    } catch (error) {
      return error as GetStagedManifestResponse;
    }
  },
  printLabels: async (values: string[]): Promise<any> => {
    try {
      return (await API.graphql({
        query: printLabelsMutation,
        variables: {
          input: {
            participantIds: values,
          },
        },
      })) as PrintLabelsResponse;
    } catch (error) {
      return error as PrintLabelsResponse;
    }
  },
  getSampleExportStatus: async (): Promise<any> => {
    try {
      return (await API.graphql({
        query: getSampleExportStatusQuery,
      })) as SampleExportStatusResponse;
    } catch (error) {
      return error as SampleExportStatusResponse;
    }
  },
  requestSampleExport: async (): Promise<any> => {
    try {
      return (await API.graphql({
        query: requestSampleExport,
      })) as RequestSampleResponse;
    } catch (error) {
      return error as RequestSampleResponse;
    }
  },
  getDailyDispatchCount: async (): Promise<any> => {
    try {
      return (await API.graphql({
        query: getDailyDispatchCountQuery,
      })) as DispatchCountResponse;
    } catch (error) {
      return error as DispatchCountResponse;
    }
  },
  getStorageBox: async (boxBarcode: string): Promise<any> => {
    try {
      return (await API.graphql({
        query: getStorageBoxQuery,
        variables: {
          boxBarcode: boxBarcode,
        },
      })) as GetStorageBoxResponse;
    } catch (error) {
      return error as GetStorageBoxResponse;
    }
  },
  getShipping: async (): Promise<any> => {
    try {
      return (await API.graphql({
        query: getShippingQuery,
      })) as GetShippingResponse;
    } catch (error) {
      return error as GetShippingResponse;
    }
  },
  updateShipping: async (values: { boxBarcode: string; operation: string }): Promise<any> => {
    try {
      return (await API.graphql({
        query: updateShippingMutation,
        variables: {
          input: {
            boxBarcode: values.boxBarcode,
            operation: values.operation,
          },
        },
      })) as UpdateShippingResponse;
    } catch (error) {
      return error as UpdateShippingResponse;
    }
  },
  dispatchShipment: async (): Promise<any> => {
    try {
      return (await API.graphql({
        query: dispatchShipmentMutation,
      })) as DispatchShipmentResponse;
    } catch (error) {
      return error as DispatchShipmentResponse;
    }
  },
  getShippingHistory: async (): Promise<any> => {
    try {
      return (await API.graphql({
        query: getShippingHistoryQuery,
      })) as GetShippingHistoryResponse;
    } catch (error) {
      return error as GetShippingHistoryResponse;
    }
  },
};

export default portalApi;
