import { useMemo } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import _ from 'lodash';
import ScannedCell from '../ScannedCell';
import { SamplePosition } from '../../../../../../../../core/types/common.types';
import ScanEvent from '../../../../../../../../components/forms/ScanEvent';
import EditSampleDialog from '../EditSampleDialog';
import { FormikErrors } from 'formik';
import { STORAGE_BOX_WIDTH } from '../../../../../../../../core/constants/forms.constants';

interface CryoboxGridProps {
  errors?: string | string[] | FormikErrors<SamplePosition>[];
  replace: (index: number, value: any) => void;
  onSelect: (index: number | undefined) => void;
  values: SamplePosition[];
  selectedSample?: number;
  canScan: boolean;
  editMode: boolean;
}

function SampleGrid({ errors, replace, onSelect, selectedSample, values, canScan, editMode }: CryoboxGridProps) {
  const firstOpen = useMemo(() => values.findIndex((item) => !item), [values]);

  const rows = _.chunk(values, STORAGE_BOX_WIDTH);

  const handleScan = (scanCode: string) => {
    let index;
    if (selectedSample !== undefined) {
      index = selectedSample;
    } else {
      index = firstOpen;
    }
    if (index > -1) {
      replace(index, {
        sampleBarcode: scanCode,
      });
    }
  };

  const deleteSample = (index: number) => {
    replace(index, null);
    onSelect(undefined);
  };

  return (
    <Box mb={4} position="relative">
      {canScan && selectedSample === undefined && <ScanEvent data-testid="scanner" onScan={handleScan} />}
      {selectedSample !== undefined && (
        <EditSampleDialog
          index={selectedSample}
          value={values[selectedSample]}
          isOpen={selectedSample !== undefined ? true : false}
          onClose={() => {
            onSelect(undefined);
          }}
          onSave={handleScan}
          onRemove={deleteSample}
        />
      )}
      <Paper elevation={5} sx={{ padding: 1 }}>
        {rows.map((row, rowIndex) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
            key={`col-${rowIndex}`}
          >
            {row.map((col, colIndex) => {
              const shelfPosition = String.fromCharCode(rowIndex + 65) + (colIndex + 1);
              const cellIndex = Math.ceil(colIndex) + Math.ceil(rowIndex * STORAGE_BOX_WIDTH);
              return (
                <ScannedCell
                  key={cellIndex}
                  value={col}
                  position={cellIndex}
                  label={shelfPosition}
                  onSelect={onSelect}
                  onRemove={deleteSample}
                  hasError={errors && errors[cellIndex]}
                  editMode={editMode}
                />
              );
            })}
          </Box>
        ))}
      </Paper>
    </Box>
  );
}

export default SampleGrid;
