import { createSlice } from '@reduxjs/toolkit';
import { PrintState } from './types';
import { StoreStatus } from '../../core/types/common.types';
import { getParticipantsByStatusThunk, printLabelsThunk } from './thunks';

// Initial State
const initialState: PrintState = {
  errors: null,
  status: StoreStatus.IDLE,
  response: { pending: [], printed: [] },
  printErrors: null,
  printStatus: StoreStatus.IDLE,
  printResponse: null,
  printCount: 0,
};

// Slice
export const printSlice = createSlice({
  name: 'print',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getParticipantsByStatusThunk.pending.type, (state) => {
      state.status = StoreStatus.BUSY;
    });

    builder.addCase(getParticipantsByStatusThunk.fulfilled.type, (state, action: any) => {
      state.status = StoreStatus.IDLE;
      const type = action.meta.arg === 'PENDING' ? 'pending' : 'printed';
      state.response = {
        ...state.response,
        [type]: action.payload.map((item: { participantId: string }): { id: string; status: string } => ({
          id: item.participantId,
          status: type,
        })),
      };
      state.errors = null;
    });

    builder.addCase(getParticipantsByStatusThunk.rejected.type, (state, { payload }: any) => {
      state.status = StoreStatus.ERROR;
      state.errors = payload;
      state.response = { pending: [], printed: [] };
    });

    builder.addCase(printLabelsThunk.pending.type, (state, action: any) => {
      state.printStatus = StoreStatus.BUSY;
      state.printCount = action.meta.arg.length;
    });

    builder.addCase(printLabelsThunk.fulfilled.type, (state, action: any) => {
      state.printStatus = StoreStatus.IDLE;
      state.printResponse = action.payload;
      state.printErrors = null;
    });
    builder.addCase(printLabelsThunk.rejected.type, (state, { payload }: any) => {
      state.printStatus = StoreStatus.ERROR;
      state.printErrors = payload;
      state.printResponse = null;
    });
  },
});

// Action creators
export const { reset } = printSlice.actions;
export * from './thunks';
export * from './selectors';
export * from './types';
export default printSlice.reducer;
