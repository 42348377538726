import MainLayout from '../MainLayout';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { SectionBox } from '@omnigenbiodata/ui';
import ROUTES from '../../../core/constants/routes.constants';
import Buttons from '../../forms/Buttons';

export interface SuccessLayoutProps {
  title: string;
  subtitle?: string | null;
  message?: string | null;
  forwardLabel?: string | null;
  onForward?: () => void;
}

function SuccessLayout({ title, subtitle = 'Success', message, forwardLabel, onForward }: SuccessLayoutProps) {
  return (
    <MainLayout title={title}>
      <SectionBox>
        <Typography variant="h4" align="center">
          {subtitle}
        </Typography>
        <Typography mb={6} mt={4} variant="h6" align="center">
          {message}
        </Typography>
        <Container maxWidth="xs">
          {onForward && forwardLabel ? (
            <Buttons forwardLabel={forwardLabel} onForward={onForward} />
          ) : (
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              component={Link}
              to={ROUTES.root}
              sx={{ fontSize: '24px' }}
            >
              Home
            </Button>
          )}
        </Container>
      </SectionBox>
    </MainLayout>
  );
}

export default SuccessLayout;
