import { Outlet } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import ROUTES from '../../core/constants/routes.constants';
import { useAuth } from '@omnigenbiodata/ui';

function PortalScene() {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to={ROUTES.auth} />;
  }

  return <Outlet />;
}

export default PortalScene;
