import { RiBarcodeLine, RiStore3Line } from 'react-icons/ri';
import { BiCheck } from 'react-icons/bi';
import { DataGrid, GridAlignment } from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { Order } from '../../core/types/common.types';
import { theme } from '@omnigenbiodata/ui';
import useMediaQuery from '@mui/material/useMediaQuery';

interface SuccessListProps {
  list: Order[];
  loading?: boolean;
  isDispatch?: boolean;
}

const columns = (total: string, isMobile: boolean, isDispatch: boolean) => [
  {
    field: 'orderNum',
    renderHeader: () => <RiStore3Line size={18} />,
  },
  {
    field: 'orderRef',
    headerName: `Orders ${isDispatch ? 'dispatched' : 'received'} this session`,
    align: 'left' as GridAlignment,
    flex: isMobile ? 3 : 2,
  },
  {
    field: 'sampleBarcode',
    renderHeader: () => <RiBarcodeLine size={18} />,
    valueFormatter: (params: any) => params.value || 'Non-viable sample',
    flex: 1,
  },
  {
    field: 'status',
    align: 'right' as GridAlignment,
    headerAlign: 'right' as GridAlignment,
    headerName: total,
    flex: 1,
    renderCell: () =>
      !isMobile ? (
        <Chip
          label="success"
          variant="outlined"
          size="small"
          style={{
            borderColor: theme.palette.success.main,
            color: theme.palette.success.main,
          }}
          icon={<BiCheck size={25} color={theme.palette.success.main} />}
        />
      ) : (
        <BiCheck size={25} color={theme.palette.success.main} />
      ),
  },
];

function SuccessList({ list, loading = false, isDispatch = false }: SuccessListProps) {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box my={4} sx={{ bgcolor: 'background.paper' }}>
      <DataGrid
        loading={loading}
        rows={list}
        hideFooter
        autoHeight
        getRowId={(row) => row.orderNum}
        columns={columns(list.length.toString(), isMobile, isDispatch)}
      />
    </Box>
  );
}

export default SuccessList;
