import { useEffect, useState } from 'react';
import MainLayout from '../../../../components/layouts/MainLayout';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useFormik } from 'formik';
import { FormInput, theme, Message } from '@omnigenbiodata/ui';
import { FaUndo } from 'react-icons/fa';
import { RiBarcodeLine } from 'react-icons/ri';
import { BsQrCode } from 'react-icons/bs';
import { MdRefresh } from 'react-icons/md';
import ScanEvent from '../../../../components/forms/ScanEvent';
import { useAppDispatch, useAppSelector } from '../../../../store';
import SuccessList from '../../../../components/SuccessList';
import ROUTES from '../../../../core/constants/routes.constants';
import BeatLoader from 'react-spinners/BeatLoader';
import {
  BARCODE_REGEX,
  INCOMING_QR_REGEX,
  MESSAGES,
  OUTGOING_QR_REGEX,
} from '../../../../core/constants/forms.constants';
import * as yup from 'yup';
import {
  isBusySelector,
  errorSelector,
  hasSuccessSelector,
  listSelector,
  dispatchSampleThunk,
  clear,
  reset,
  countSelector,
  getCountThunk,
  countIsBusySelector,
  countErrorSelector,
} from '../../../../store/dispatch';
import Buttons from '../../../../components/forms/Buttons';
import { useTranslation } from 'react-i18next';

const wrongQR = (code: string) => new RegExp(INCOMING_QR_REGEX, 'g').test(code);

const validationSchema = yup.object().shape({
  qrCode: yup
    .string()
    .required(MESSAGES.qrRequired)
    .test('wrongQR', MESSAGES.wrongQR, (value) => !wrongQR(value!))
    .matches(new RegExp(OUTGOING_QR_REGEX, 'g'), MESSAGES.qrInvalid),
  sampleBarcode: yup.string().required(MESSAGES.barcodeRequired).matches(BARCODE_REGEX, MESSAGES.barcodeInvalid),
});

interface LinkPair {
  sampleBarcode: string;
  orderRef: string;
}

function DispatchScene() {
  const { t } = useTranslation('portal');
  const [open, setOpen] = useState(false);
  const [lastScanned, setLast] = useState<LinkPair | null>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isBusy = useAppSelector(isBusySelector);
  const hasSuccess = useAppSelector(hasSuccessSelector);
  const errors = useAppSelector(errorSelector);
  const list = useAppSelector(listSelector);
  const count = useAppSelector(countSelector);
  const countBusy = useAppSelector(countIsBusySelector);
  const countErrors = useAppSelector(countErrorSelector);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      qrCode: '',
      sampleBarcode: '',
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: () => {
      dispatch(dispatchSampleThunk(formik.values));
      const ref = formik.values.qrCode.match(/\d{4,}$/g); // extracts order number from qr code
      setLast({
        sampleBarcode: formik.values.sampleBarcode,
        orderRef: ref ? ref[0] : formik.values.qrCode.slice(-8),
      });
      formik.resetForm();
    },
  });

  useEffect(() => {
    dispatch(getCountThunk());
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  const handleScan = (scanCode: string) => {
    if (!isBusy) {
      if (hasSuccess || errors) {
        dispatch(clear());
      }
      if (scanCode) {
        if (!formik.values.qrCode) {
          formik.setFieldValue('qrCode', scanCode);
        } else {
          formik.setFieldValue('sampleBarcode', scanCode);
        }
      }
    }
  };

  const getCount = () => {
    if (!countBusy) {
      dispatch(getCountThunk());
    }
  };

  const finish = () => {
    if (!errors && !formik.values.sampleBarcode) {
      navigate(ROUTES.root);
    } else setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MainLayout title={t('dispatch.title')}>
      <form onSubmit={formik.handleSubmit}>
        <Container maxWidth="sm">
          <Typography variant="body1" align="center" p={2}>
            {t('dispatch.text')}
          </Typography>
          {errors && (
            <Message
              severity="error"
              onClose={() => dispatch(clear())}
              title={t('alerts.dispatchFailed.title', { ...lastScanned }) as string}
            >
              {errors[0].message}
            </Message>
          )}
          <ScanEvent data-testid="scanner" onScan={handleScan} />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormInput
                label={t('forms.qrCode')}
                type="text"
                name={`qrCode`}
                error={formik.errors.qrCode}
                touched={formik.touched.qrCode}
                onChange={formik.handleChange}
                onBlur={() => undefined}
                value={formik.values.qrCode}
                readOnly
                disabled={!formik.values?.qrCode}
                startAdornment={<BsQrCode fontSize={20} color={theme.palette.grey[500]} />}
                endAdornment={
                  formik.values.qrCode && (
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => {
                        formik.setFieldValue('qrCode', '');
                      }}
                    >
                      <FaUndo fontSize={20} color={theme.palette.error.dark} />
                    </IconButton>
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInput
                label={t('forms.barcode')}
                type="text"
                name={`sampleBarcode`}
                error={formik.errors.sampleBarcode}
                touched={formik.touched.sampleBarcode}
                onChange={formik.handleChange}
                onBlur={() => undefined}
                value={formik.values.sampleBarcode}
                readOnly
                disabled={!formik.values?.sampleBarcode}
                startAdornment={<RiBarcodeLine fontSize={25} color={theme.palette.grey[500]} />}
                endAdornment={
                  formik.values.sampleBarcode && (
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => {
                        formik.setFieldValue('sampleBarcode', '');
                      }}
                    >
                      <FaUndo fontSize={20} color={theme.palette.error.dark} />
                    </IconButton>
                  )
                }
              />
            </Grid>
          </Grid>
          <SuccessList list={list} loading={isBusy} isDispatch />
          <Buttons
            backLabel={t('buttons.finish') as string}
            onBack={finish}
            forwardLabel={t('buttons.dispatchConfirm')}
            isDisabled={isBusy}
            onForward={formik.handleSubmit}
          />
          <Box py={2}>
            {countBusy ? (
              <BeatLoader color={'#cccccc'} loading={true} size={7} />
            ) : countErrors ? (
              <Message severity="error" title={t('alerts.dispatchCountFailed.title') as string}>
                {countErrors[0].message}
              </Message>
            ) : (
              <Typography variant="subtitle1" alignContent="center">
                {t('dispatch.count')} {count}
                <IconButton data-testid="refresh-count" onClick={getCount} size="small">
                  <MdRefresh fontSize={18} color={theme.palette.primary.dark} />
                </IconButton>
              </Typography>
            )}
          </Box>
        </Container>
      </form>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-finish" maxWidth="xs">
        <DialogContent>
          <Typography variant="h6">{t('dispatch.dialog')}</Typography>
        </DialogContent>
        <DialogActions>
          <Buttons
            forwardLabel={t('buttons.finish')}
            onForward={() => navigate(ROUTES.root)}
            onBack={handleClose}
            backLabel={t('buttons.cancel') as string}
          />
        </DialogActions>
      </Dialog>
    </MainLayout>
  );
}

export default DispatchScene;
