import { Route, Routes, Navigate } from 'react-router-dom';
import { AuthRoute } from '@omnigenbiodata/ui';
import ROUTES from '../core/constants/routes.constants';

import AuthScene from './AuthScene';
import Login from './AuthScene/scenes/Login';
import MultiFactor from './AuthScene/scenes/MultiFactor';
import NewPassword from './AuthScene/scenes/NewPassword';
import ResetPassword from './AuthScene/scenes/ResetPassword';
import SubmitResetPassword from './AuthScene/scenes/SubmitResetPassword';
import Logout from './AuthScene/scenes/Logout';
import HomeScene from './PortalScene/scenes/HomeScene';
import DispatchScene from './PortalScene/scenes/DispatchScene';
import ReceiveScene from './PortalScene/scenes/ReceiveScene';
import PrintScene from './PortalScene/scenes/PrintScene';
import PrintSelection from './PortalScene/scenes/PrintScene/scenes/SelectionScene';
import PrintSuccess from './PortalScene/scenes/PrintScene/scenes/SuccessScene';
import StorageManageScene from './PortalScene/scenes/StorageScene/scenes/ManageScene';
import StorageCreateScene from './PortalScene/scenes/StorageScene/scenes/CreateScene';
import PortalScene from './PortalScene';
import SampleSearchScene from './PortalScene/scenes/SampleSearchScene';
import ShippingScene from './PortalScene/scenes/ShippingScene';
import ShippingCreateScene from './PortalScene/scenes/ShippingScene/scenes/CreateShipment';
import ShippingSuccessScene from './PortalScene/scenes/ShippingScene/scenes/SuccessScene';
import ShippingHistoryScene from './PortalScene/scenes/ShippingScene/scenes/ShippingHistory';

function Scenes() {
  return (
    <Routes>
      <Route path={ROUTES.auth} element={<AuthScene />}>
        <Route path={ROUTES.auth} element={<Login />} />
        <Route path={ROUTES.authMfa} element={<MultiFactor />} />
        <Route path={ROUTES.authNewPassword} element={<NewPassword />} />
        <Route path={ROUTES.authResetPassword} element={<ResetPassword />} />
        <Route path={ROUTES.authSubmitResetPassword} element={<SubmitResetPassword />} />
        <Route path={ROUTES.authSignOut} element={<Logout />} />
      </Route>
      <Route path={ROUTES.root} element={<AuthRoute path={ROUTES.root} element={PortalScene} />}>
        <Route path={ROUTES.root} element={<HomeScene />} />
        <Route path={ROUTES.search} element={<SampleSearchScene />} />
        <Route path={ROUTES.dispatch} element={<DispatchScene />} />
        <Route path={ROUTES.receive} element={<ReceiveScene />} />
        <Route path={ROUTES.print} element={<PrintScene />}>
          <Route path={ROUTES.print} element={<PrintSelection />} />
          <Route path={ROUTES.printSuccess} element={<PrintSuccess />} />
        </Route>
        <Route path={ROUTES.storageManage} element={<StorageManageScene />} />
        <Route path={ROUTES.storageCreate} element={<StorageCreateScene />} />
        <Route path={ROUTES.storageEdit} element={<StorageCreateScene />} />
        <Route path={ROUTES.shipping} element={<ShippingScene />}>
          <Route path={ROUTES.shipping} element={<ShippingCreateScene />} />
          <Route path={ROUTES.shippingSuccess} element={<ShippingSuccessScene />} />
          <Route path={ROUTES.shippingHistory} element={<ShippingHistoryScene />} />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to={ROUTES.root} replace />} />
    </Routes>
  );
}

export default Scenes;
