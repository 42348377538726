import { createAsyncThunk } from '@reduxjs/toolkit';
import portalApi from '../../core/api/portal.api';
import { GetShippingHistoryResponse, GraphQlError } from '../../core/types/common.types';

export const shippingHistoryThunk = createAsyncThunk<
  // Return type of the payload creator
  GetShippingHistoryResponse,
  // First argument to the payload creator
  undefined,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('shippingHistory', async (values, thunkApi) => {
  const response: GetShippingHistoryResponse = await portalApi.getShippingHistory();

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }
  return response.data?.listShipments as GetShippingHistoryResponse;
});
