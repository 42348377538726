import { RootState } from '../index';
import { Shipment, StoreStatus } from '../../core/types/common.types';

export const errorSelector = (state: RootState): any | null => state.shippingHistory.errors || null;

export const isBusySelector = (state: RootState): boolean => state.shippingHistory.status === StoreStatus.BUSY;

export const responseSelector = (state: RootState): Shipment[] | null => state.shippingHistory.response || null;

export const hasSuccessSelector = (state: RootState): boolean => (state.shippingHistory.response ? true : false);
