import { useEffect } from 'react';
import MainLayout from '../../../../../../components/layouts/MainLayout';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Loader, Message, Tabs } from '@omnigenbiodata/ui';
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import ROUTES from '../../../../../../core/constants/routes.constants';
import SelectionTable from './components/SelectionTable';
import {
  isBusySelector,
  isPrintingSelector,
  errorSelector,
  hasPrintSuccessSelector,
  hasPrintErrorSelector,
  participantsByPrintedStatusSelector,
  participantsByPendingStatusSelector,
  getParticipantsByStatusThunk,
  printLabelsThunk,
} from '../../../../../../store/print';
import { useTranslation } from 'react-i18next';

function SelectionScene() {
  const { t } = useTranslation('portal');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isBusy = useAppSelector(isBusySelector);
  const isPrinting = useAppSelector(isPrintingSelector);
  const hasPrintSuccess = useAppSelector(hasPrintSuccessSelector);
  const hasPrintError = useAppSelector(hasPrintErrorSelector);
  const printed = useAppSelector(participantsByPrintedStatusSelector);
  const pending = useAppSelector(participantsByPendingStatusSelector);
  const errors = useAppSelector(errorSelector);

  useEffect(() => {
    dispatch(getParticipantsByStatusThunk('PENDING'));
    dispatch(getParticipantsByStatusThunk('CREATED'));
  }, [dispatch]);

  useEffect(() => {
    if (hasPrintError && !isPrinting) {
      dispatch(getParticipantsByStatusThunk('PENDING'));
      dispatch(getParticipantsByStatusThunk('CREATED'));
    }
  }, [hasPrintError, dispatch, isPrinting]);

  useEffect(() => {
    if (hasPrintSuccess && !errors) {
      navigate(ROUTES.printSuccess);
    }
  }, [errors, hasPrintSuccess, navigate]);

  const printLabels = (selection: string[]) => {
    dispatch(printLabelsThunk(selection));
  };

  return (
    <MainLayout title={t('print.title')}>
      <Container maxWidth="sm" sx={{ paddingX: 0 }}>
        <Typography mb={2} variant="body1" align="center">
          {t('print.text')}
        </Typography>
        {errors && (
          <Message severity="error" title={t('alerts.printFailed.title') as string}>
            {errors[0].message}
          </Message>
        )}
        <Tabs>
          <SelectionTable
            printFn={printLabels}
            title={t('print.tabs.pending') as string}
            loading={isBusy}
            data={pending}
          />
          <SelectionTable
            printFn={printLabels}
            title={t('print.tabs.printed') as string}
            loading={isBusy}
            data={printed}
          />
        </Tabs>
      </Container>
      <Loader isVisible={isPrinting} label={t('loaders.print') as string} />
    </MainLayout>
  );
}

export default SelectionScene;
