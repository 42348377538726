export const ROOT = `/`;
export const AUTH_BASE = `${ROOT}auth/`;
export const DISPATCH_BASE = `${ROOT}dispatch/`;
export const RECIEVE_BASE = `${ROOT}receive/`;
export const PRINT_BASE = `${ROOT}print/`;
export const STORAGE_BASE = `${ROOT}storage/`;
export const SHIPPING_BASE = `${ROOT}shipping/`;

export const ROUTES = {
  root: ROOT,
  auth: AUTH_BASE,
  authMfa: `${AUTH_BASE}mfa`,
  authNewPassword: `${AUTH_BASE}newpassword`,
  authResetPassword: `${AUTH_BASE}resetpassword`,
  authSubmitResetPassword: `${AUTH_BASE}submitresetpassword`,
  authSignOut: `${AUTH_BASE}logout`,
  dispatch: `${DISPATCH_BASE}`,
  dispatchSuccess: `${DISPATCH_BASE}success`,
  receive: `${RECIEVE_BASE}`,
  print: `${PRINT_BASE}`,
  printSuccess: `${PRINT_BASE}success`,
  storageCreate: `${STORAGE_BASE}create`,
  storageEdit: `${STORAGE_BASE}edit/:boxBarcode`,
  storageManage: `${STORAGE_BASE}`,
  search: `${ROOT}search`,
  shipping: `${SHIPPING_BASE}`,
  shippingConfirm: `${SHIPPING_BASE}confirm`,
  shippingSuccess: `${SHIPPING_BASE}success`,
  shippingHistory: `${SHIPPING_BASE}history`,
};

export default ROUTES;
