import { useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import Buttons from '../../../../../../../../components/forms/Buttons';

interface SelectionTableProps {
  data: any;
  title?: string;
  loading: boolean;
  printFn: (selection: string[]) => void;
}

const columns = [
  { field: 'id', headerName: 'Participant ID', flex: 2 },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
  },
];

function SelectionTable({ data, loading, printFn }: SelectionTableProps) {
  const apiRef = useGridApiRef();
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const submit = () => {
    if (rowSelectionModel.length > 0) {
      printFn(rowSelectionModel);
    }
  };

  const handleSelection = (newSelection: any) => {
    setRowSelectionModel(newSelection.slice(0, 50));
  };
  return (
    <>
      <Box sx={{ width: '100%', marginBottom: 2 }}>
        <DataGrid
          apiRef={apiRef}
          loading={loading}
          rows={data}
          autoHeight
          pageSizeOptions={[10, 25, 50]}
          checkboxSelection
          columns={columns}
          onRowSelectionModelChange={handleSelection}
          rowSelectionModel={rowSelectionModel}
          initialState={{
            ...data.initialState,
            pagination: { paginationModel: { pageSize: 50 } },
          }}
        />
      </Box>
      <Buttons onForward={submit} forwardLabel="Print" />
    </>
  );
}

export default SelectionTable;
