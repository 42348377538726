import { createAsyncThunk } from '@reduxjs/toolkit';
import portalApi from '../../core/api/portal.api';
import { ReceiveSampleResponse, GraphQlError } from '../../core/types/common.types';
import { GraphQLError } from 'graphql';

export const receiveSampleThunk = createAsyncThunk<
  // Return type of the payload creator
  ReceiveSampleResponse,
  // First argument to the payload creator
  { qrCode: string; sampleBarcode?: string; viable: boolean },
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('receive', async (values, thunkApi) => {
  const response: ReceiveSampleResponse = await portalApi.receiveSample(values);

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  } else if (!response.data?.receiveSample) {
    throw new GraphQLError('Server failed to respond');
  }
  return response.data.receiveSample as ReceiveSampleResponse;
});
