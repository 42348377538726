import { RootState } from '../index';
import { ExportStatus, StorageBox, StoreStatus } from '../../core/types/common.types';

export const errorSelector = (state: RootState): any | null => state.storage.errors || null;

export const isBusySelector = (state: RootState): boolean => state.storage.status === StoreStatus.BUSY;

export const responseSelector = (state: RootState): StorageBox | null => state.storage.response || null;

export const hasSuccessSelecter = (state: RootState): boolean => (state.storage.response ? true : false);

export const boxErrorSelector = (state: RootState): any | null => state.storage.boxErrors || null;

export const boxIsBusySelector = (state: RootState): boolean => state.storage.boxStatus === StoreStatus.BUSY;

export const boxResponseSelector = (state: RootState): StorageBox | null => state.storage.boxResponse || null;

export const boxesErrorSelector = (state: RootState): any | null => state.storage.boxesErrors || null;

export const boxesIsBusySelector = (state: RootState): boolean => state.storage.boxesStatus === StoreStatus.BUSY;

export const boxesResponseSelector = (state: RootState): StorageBox[] | null => state.storage.boxesResponse || null;

export const boxesHasSuccessSelecter = (state: RootState): boolean => (state.storage.boxesResponse ? true : false);

export const exportIsBusySelector = (state: RootState): boolean => state.storage.exportStatus === StoreStatus.BUSY;

export const exportResponseSelector = (state: RootState): ExportStatus | null => state.storage.exportResponse || null;

export const exportErrorSelector = (state: RootState): any | null => state.storage.exportErrors || null;
