import MainLayout from '../../../../components/layouts/MainLayout';
import { Link, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { TiPrinter } from 'react-icons/ti';
import { FaTruckLoading } from 'react-icons/fa';
import { BiBarcodeReader } from 'react-icons/bi';
import { RiFileList3Line } from 'react-icons/ri';
import { TbBooks } from 'react-icons/tb';
import { HiOutlineArchive } from 'react-icons/hi';
import { MdStorage } from 'react-icons/md';
import ROUTES from '../../../../core/constants/routes.constants';
import SampleSearch from '../SampleSearchScene/components/SampleSearch';
import { sampleSearchThunk } from '../../../../store/search';
import { useAppDispatch } from '../../../../store';
import { useAuth } from '@omnigenbiodata/ui';
import { isAdmin } from '../../../../core/utils/auth';

function HomeScene() {
  const navigateTo = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const admin = isAdmin(user);

  const handleSampleSearch = (values: any) => {
    dispatch(sampleSearchThunk(values));
    navigateTo(ROUTES.search);
  };

  return (
    <MainLayout home>
      <Typography variant="h3" component="h1" align="center" py={2} paragraph>
        Laboratory Portal
      </Typography>
      <SampleSearch onSearch={handleSampleSearch} showTitle />
      <Grid py={2} container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            color="primary"
            size="xl"
            fullWidth
            component={Link}
            to={ROUTES.print}
            startIcon={<TiPrinter size={40} />}
          >
            Print Labels
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            color="primary"
            size="xl"
            fullWidth
            component={Link}
            to={ROUTES.dispatch}
            startIcon={<TbBooks size={40} />}
          >
            Dispatch Samples
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            color="primary"
            size="xl"
            fullWidth
            component={Link}
            to={ROUTES.receive}
            startIcon={<BiBarcodeReader size={40} />}
          >
            Receive Samples
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            color="primary"
            size="xl"
            fullWidth
            component={Link}
            to={ROUTES.storageCreate}
            startIcon={<HiOutlineArchive size={40} />}
          >
            Store Samples
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            color="primary"
            size="xl"
            fullWidth
            component={Link}
            to={ROUTES.storageManage}
            startIcon={<MdStorage size={40} />}
          >
            Manage Storage
          </Button>
        </Grid>
        {admin && (
          <>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="primary"
                size="xl"
                fullWidth
                component={Link}
                to={ROUTES.shipping}
                startIcon={<FaTruckLoading size={40} />}
              >
                Ship Sample Boxes
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="primary"
                size="xl"
                fullWidth
                component={Link}
                to={ROUTES.shippingHistory}
                startIcon={<RiFileList3Line size={40} />}
              >
                Shipping History
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </MainLayout>
  );
}

export default HomeScene;
