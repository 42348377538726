import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DataGrid, GridAlignment } from '@mui/x-data-grid';
import { useAppDispatch } from '../../../../../../../../store';
import { Shipment } from '../../../../../../../../core/types/common.types.js';
import Buttons from '../../../../../../../../components/forms/Buttons';
import { dispatchShipmentThunk } from '../../../../../../../../store/shipping';

interface ConfirmationModalProps {
  staged: Shipment | null;
  isOpen: boolean;
  onClose: () => void;
  t: any;
}

const columns = () => [
  {
    field: 'barcode',
    headerName: 'Box',
    flex: 0.5,
  },
  {
    field: 'count',
    headerName: 'Samples',
    headerAlign: 'center' as GridAlignment,
    flex: 1,
    align: 'center' as GridAlignment,
  },
];

const ConfirmationModal = ({ isOpen, onClose, staged, t }: ConfirmationModalProps) => {
  const dispatch = useAppDispatch();

  const onConfirm = () => {
    onClose();
    dispatch(dispatchShipmentThunk());
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth="md"
      fullWidth
      onClose={onClose}
      aria-labelledby="shipping-confirm-modal"
      data-testid="shipping-confirm-modal"
    >
      <DialogTitle id="end-dialog-title">
        <Typography my={1} variant="h4" component="div" align="center">
          {t('createShipment.confirm.title')}
        </Typography>
        <Typography variant="body1" align="center">
          {t('createShipment.confirm.text', {
            sampleTotal: staged ? staged.totalSampleCount : 0,
            boxTotal: staged ? staged.sampleBoxes.length : 0,
          })}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DataGrid
          rows={staged?.sampleBoxes || []}
          autoHeight
          getRowId={(row) => row.barcode}
          columns={columns()}
          pageSizeOptions={[10, 25, 50]}
          initialState={{ pagination: { paginationModel: { pageSize: 50 } } }}
        />
      </DialogContent>
      <DialogActions>
        <Box px={2} pb={2} width="100%">
          <Buttons
            forwardLabel={t('buttons.confirm')}
            onForward={onConfirm}
            onBack={onClose}
            backLabel={t('buttons.back')}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
